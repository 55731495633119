/** Import react/libraries section **/
import React from 'react';
import PropTypes from 'prop-types';

/** Import resources section **/
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

/** Import styles section **/

/** Import helpers section **/
import { color_primary, color_delete } from '../../styles/Colors';

/** Import component section **/

const iconStyle = {
  background: color_primary,
  color: 'white',
  width: '20px',
  height: '20px',
  borderRadius: '4px',
  padding: '4px',
  cursor: 'pointer',
  margin: '5px'
};

const iconDeleteStyle = {
  background: color_delete
};

export const ActionsCellComponent = ({ item, onEdit, onDelete }) => {
  return (
    <>
      {onEdit && <EditIcon style={iconStyle} onClick={() => onEdit(item)} />}
      {onDelete && (
        <DeleteIcon
          style={{ ...iconStyle, ...iconDeleteStyle }}
          onClick={() => onDelete(item)}
        />
      )}
    </>
  );
};

ActionsCellComponent.propTypes = {
  item: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};

export default ActionsCellComponent;
