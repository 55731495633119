/** Import react/libraries section **/
import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

/** Import resources section **/
import { Button, Paper, TextField } from '@material-ui/core';

/** Import styles section **/
import './RegistrationStyles.scss';

/** Import helpers section **/

/** Import component section **/
import Logos from '../../components/Logos';
import MUISelect from '../../components/inputs/MUISelect';

const RegistrationView = ({
  onSubmit,
  inProcess,
  registered,
  schools,
  onSchoolChange
}) => {
  const { register, handleSubmit } = useForm();

  const handleOnSubmit = (data) => {
    onSubmit(data);
  };

  return (
    <div className="registration">
      <Paper className="registration_container">
        <h3>School representative registration</h3>
        <form
          className="registration_container_form"
          onSubmit={handleSubmit(handleOnSubmit)}
        >
          <div className="registration_container_form_fields">
            <MUISelect
              label="School"
              name="school"
              id="school"
              items={schools}
              idSelector="id"
              selector="name"
              noSelectedLabel="Select"
              onChange={(event) => onSchoolChange(event.value)}
            />

            <TextField
              label="Email"
              className="registration_container_form-email"
              id="email"
              name="email"
              type="text"
              variant="outlined"
              {...register('email', {
                required: true,
                message: 'Email required'
              })}
            />

            <TextField
              label="First Name"
              id="firstName"
              name="first_name"
              className="registration_container_form-firstName"
              type="text"
              variant="outlined"
              {...register('first_name', {
                required: true,
                message: 'First name required'
              })}
            />

            <TextField
              label="Last Name"
              className="registration_container_form-lastName"
              id="lastName"
              name="last_name"
              type="text"
              variant="outlined"
              {...register('last_name', {
                required: true,
                message: 'Last name required'
              })}
            />

            <TextField
              label="Password"
              id="password"
              className="registration_container_form-password"
              name="password"
              type="password"
              variant="outlined"
              {...register('password', {
                required: true,
                message: 'Password required'
              })}
            />

            <TextField
              label="Confirm Password"
              id="passwordConf"
              className="registration_container_form-passwordConf"
              name="password_confirmation"
              type="password"
              variant="outlined"
              {...register('password_confirmation', {
                required: true,
                message: 'Password required'
              })}
            />
          </div>
          <div className="registration_container_form_bottom">
            <div className="registration_container_form_bottom-logos">
              {<Logos />}
            </div>

            <div className="registration_container_form_bottom-buttons">
              <Link to="/" underline="hover">
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  className="g-button cancel"
                >
                  Back
                </Button>
              </Link>

              <Button onClick={handleSubmit} type="submit">
                {inProcess === true ? 'Saving...' : 'Registration'}
              </Button>
            </div>
          </div>
        </form>
      </Paper>
    </div>
  );
};

export default RegistrationView;
