// System colors
export const color_primary = '#19325c';
export const color_secondary = '#f37722';
export const color_complementary = '#00c0ea';
export const color_complementary_2 = '#e2eaf4';
export const color_complementary_3 = '#807fff';
export const color_complementary_4 = '#f9ec31';
export const color_light = '#f0f0f0';
export const color_white = '#ffffff';
export const color_dark = '#28292b';
export const color_medium = '#adadad';
export const color_normal = '#707070';
export const color_disabled = '#e4e4e4';
export const color_cancel = '#adadad';
export const color_delete = '#fa6347';
export const color_check = '#3bbb3e';
