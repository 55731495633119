/** Import react/libraries section **/
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

/** Import resources section **/

/** Import helpers section **/
import { clearSession } from '../../store/slices/session-slice';
import * as routes from '../../URL-routes';

/** Import component section **/
import HeaderView from './HeaderView';

const adminMenu = [
  {
    title: 'Schools ',
    link: routes.SCHOOLS_ROUTE
  },
  {
    title: 'Events',
    link: routes.EVENTS_ROUTE
  },
  {
    title: 'Event registrations',
    link: routes.EVENT_REGISTRATIONS_ROUTE
  },
  {
    title: 'Event dashboard',
    link: routes.EVENT_DASHBOARD_ROUTE
  },
  {
    title: 'Event configuration',
    link: routes.EVENT_CONFIG_ROUTE
  }
];

const schoolMenu = [
  {
    title: 'School information',
    link: routes.REGISTRY_ROUTE
  },
  {
    title: 'Additional advisors',
    link: routes.ADVISORS_ROUTE
  },
  {
    title: 'Observers',
    link: routes.OBSERVERS_ROUTE
  },
  {
    title: 'Confirmation & payment',
    link: routes.PAYMENT_ROUTE
  },
  {
    title: 'Assign delegates',
    link: routes.ASSIGN_ROUTE
  }
];

export const HeaderComponent = () => {
  const dispatch = useDispatch();
  let role = useSelector((store) => store.session.role);
  let userName = useSelector((store) => store.session.profile?.name);
  let schoolName = useSelector((store) => store.session.profile?.school_name);

  const handleOnLogout = () => {
    dispatch(clearSession());
  };

  const menuItems = role === 1 ? adminMenu : role === 2 ? schoolMenu : [];

  return (
    <HeaderView
      items={menuItems}
      userName={userName}
      schoolName={schoolName}
      onLogout={handleOnLogout}
    />
  );
};

export default HeaderComponent;
