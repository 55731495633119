/** Import react/libraries section **/
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

/** Import styles section **/
import './CommitteeStyles.scss';

/** Import resources section **/
import { setUpdate } from '../../../store/slices/refresh-slice';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import services section **/
import { deleteCommitteeService } from '../../../api/services/event-configuration-services';
import { getCommitteReportService } from '../../../api/services/reports-services';

/** Import components section **/
import ModalComponent from '../../../components/Modal/ModalComponent';
import CommitteeView from './CommitteeView';
import OrganismModal from '../OrganismModal';

const OrganismModel = {
  id: null,
  name: null,
  committee_id: null,
  priority: 0
};

export const CommitteeComponent = ({ data, onEditCommittee }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalMode, setModalMode] = useState('');

  let dispatch = useDispatch();

  const handleOnDownloadReport = () => {
    getCommitteReportService(data)
      .then((response) => {
        window.open(response?.url, '_blank');
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Error, can't get report");
        console.error('Error getting report: ', err);
      })
      .finally(() => {});
  };

  const handleOnDeleteCommittee = (event) => {
    event.stopPropagation();
    setModalMode('deleteCommittee');
  };

  const handleOnDeleteCommitteeConfirm = () => {
    deleteCommitteeService(data)
      .then((response) => {
        toast.success('Committee deleted');
        onRefreshData();
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Error, can't delete committee");
        console.error('Error deleting committee: ', err);
      })
      .finally(() => {
        clearStates();
      });
  };

  const handleOnAddOrganism = () => {
    const newItem = JSON.parse(JSON.stringify(OrganismModel));
    newItem.committee_id = data.id;
    setSelectedItem(newItem);
    setModalMode('addOrganism');
  };

  const handleOnModalClose = () => {
    clearStates();
  };
  const clearStates = () => {
    setSelectedItem(null);
    setModalMode('');
  };

  const onRefreshData = () => {
    dispatch(setUpdate({ update: true }));
  };

  return (
    <>
      <CommitteeView
        code={data?.code}
        name={data?.name}
        level={data?.level}
        organisms={data?.organisms}
        onAddOrganism={handleOnAddOrganism}
        onDownloadReport={handleOnDownloadReport}
        onDeleteCommittee={handleOnDeleteCommittee}
        onEditCommittee={onEditCommittee}
      />
      {modalMode === 'deleteCommittee' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteCommitteeConfirm}
          title="Delete committee"
        >
          {'Are you sure to delete the committee ' + data?.name + ' ?'}
        </ModalComponent>
      )}
      {selectedItem && modalMode === 'addOrganism' && (
        <OrganismModal item={selectedItem} onClose={handleOnModalClose} />
      )}
    </>
  );
};

export default CommitteeComponent;
