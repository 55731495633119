/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import helpers section **/

/** Import styles section **/
import './AssignStyles.scss';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import GetAppIcon from '@material-ui/icons/GetApp';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';
import ActionsCell from '../../components/ActionsCell';

const AssignView = ({
  data,
  inProcess,
  confirmationCheck,
  onDownloadReport,
  onConfirmationChange,
  onEdit,
  onDelete
}) => {
  const LevelCell = ({ item }) => {
    return item?.committee?.level === 2 ? <>High School</> : <>Middle School</>;
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'id',
        selector: 'id',
        omit: true
      },
      {
        name: 'Committee',
        selector: (row) => row?.committee?.code + ' - ' + row?.committee?.name,
        sortable: true,
        center: true,
        grow: 3
      },
      {
        name: 'Level',
        selector: (row) => row?.committee?.level,
        cell: (row) => <LevelCell item={row} />,
        sortable: true,
        center: true
      },
      {
        name: 'Organization',
        selector: (row) => row?.name,
        sortable: true,
        center: true,
        grow: 2
      },
      {
        name: 'First Name',
        selector: (row) => row?.assigned?.first_name,
        sortable: true,
        center: true
      },
      {
        name: 'Last name',
        selector: (row) => row?.assigned?.last_name,
        sortable: true,
        center: true
      },
      {
        name: 'Student grade',
        selector: (row) => row?.assigned?.grade,
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => (
          <ActionsCell
            item={row}
            onEdit={onEdit}
            onDelete={row?.assigned ? onDelete : null}
          />
        ),
        ignoreRowClick: true,
        width: '100px',
        omit: confirmationCheck
      }
    ],
    [onEdit, onDelete, confirmationCheck]
  );

  return (
    <>
      <PageTitle title="Assign delegates" />
      <Paper className="assign">
        <GetAppIcon className="assign_download" onClick={onDownloadReport} />
        <div className="assign_confirm">
          <Switch
            checked={confirmationCheck}
            onChange={onConfirmationChange}
            name="active"
            color="secondary"
            disabled={confirmationCheck}
          />
          <div>I confirm delegates assignation</div>
        </div>
      </Paper>
      <DataTable data={data} columns={tableColumns} loading={inProcess} />
    </>
  );
};

export default AssignView;
