/** Import react/libraries section **/
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  update: false
};

const refreshSlice = createSlice({
  name: 'refresh',
  initialState: initialState,
  reducers: {
    setUpdate: (state, { payload: { update } }) => {
      state.update = update;
    }
  }
});
export default refreshSlice.reducer;

export const { setUpdate } = refreshSlice.actions;
