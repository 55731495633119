/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import services section **/
import {
  createAssignService,
  updateAssignService
} from '../../../api/services/assign-services';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/

/** Import component section **/
import AssignModalView from './AssignModalView';

const AssignModalComponent = ({ item, onClose, onUpdateList }) => {
  const [itemData, setItemData] = useState({});

  useEffect(() => {
    setItemData(item);
  }, [item]);

  const handleOnInputChange = (data) => {
    const { value, name } = data;
    setItemData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnSave = (event) => {
    event.preventDefault();
    if (item?.id) {
      updateAssignService(itemData)
        .then((response) => {
          toast.success('Assign updated');
          onUpdateList();
        })
        .catch((err) => {
          if (err?.response_status) toast.error("Error, can't update");
        });
    } else {
      createAssignService(itemData)
        .then(() => {
          toast.success('Assign added');
          onUpdateList();
        })
        .catch((err) => {
          if (err?.response_status) toast.error("Error, can't save");
        });
    }
  };

  return (
    <AssignModalView
      data={itemData}
      onInputChange={handleOnInputChange}
      onSave={handleOnSave}
      onClose={onClose}
    />
  );
};

export default AssignModalComponent;
