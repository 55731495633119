import { createMuiTheme } from '@material-ui/core/styles';
import { green, grey, red } from '@material-ui/core/colors';
import * as colors from './Colors';

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        height: '40px',
        minWidth: '100px',
        color: 'white',
        fontSize: '10px',
        textTransform: 'uppercase',
        borderRadius: '6px',
        backgroundColor: colors.color_secondary,
        '&:hover': {
          backgroundColor: colors.color_secondary
        },
        '&:disabled': {
          color: colors.color_cancel,
          backgroundColor: colors.color_disabled
        }
      },
      // outlined: {
      //   backgroundColor: '#a0a0a0',
      //   border: 'none',
      //   '&:hover': {
      //     backgroundColor: '#a0a0a0'
      //   }
      // },
      text: {
        padding: '0 15px'
      }
    },
    MuiCard: {
      root: {
        borderRadius: 12
      }
    },
    MuiPaper: {
      root: {
        borderRadius: 12
      }
    }
  },
  status: {
    danger: '#ff0000'
  },
  palette: {
    primary: {
      main: colors.color_primary
    },
    secondary: {
      main: colors.color_secondary
    },
    light: '#F2F2F2',
    complementary: {
      main: '#76b06e'
    },
    complementary2: {
      main: '#006d7b'
    },
    drawerBackground: {
      main: '#006D7B'
    },
    background: {
      default: 'white',
      paper: 'white'
    },
    success: {
      light: '#14ac7e',
      main: green['600']
    },
    error: {
      main: red.A700
    },
    dark: '#28292B',
    normal: '#707070'
  },
  typography: {
    fontFamily: [
      'Gotham-Book',
      'Open Sans',
      'Tahoma',
      'Segoe UI',
      'sans-serif'
    ].join(','),
    fontSize: 11,
    // fontWeight: 200,
    body2: {
      // fontWeight: 600,
    },
    h3: {
      fontSize: 22,
      fontWeight: 'bolder'
    },
    h4: {
      textTransform: 'uppercase',
      fontSize: 12,
      color: grey[800]
    },
    h5: {
      fontSize: 16
    }
  }
});

export default theme;
