/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

/** Import services section **/
import {
  getAssignablesSchoolsService,
  assignSchoolService
} from '../../../api/services/event-configuration-services';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/
import { setUpdate } from '../../../store/slices/refresh-slice';

/** Import component section **/
import SchoolAssignModalView from './SchoolAssignModalView';

const SchoolAssignModalComponent = ({ item, onClose }) => {
  const [itemData, setItemData] = useState({});
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);

  let dispatch = useDispatch();
  useEffect(() => {
    setItemData(item);
    setSelectedSchool(item?.events_schools_id);
  }, [item]);

  const getSchools = useCallback(() => {
    getAssignablesSchoolsService({ level: item?.level })
      .then((response) => {
        setSchools(response);
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't load schools");
        console.error('Error getting schools: ', err);
      })
      .finally(() => {});
  }, [item?.level]);

  useEffect(() => {
    getSchools();
  }, [getSchools]);

  const handleOnSchoolChange = (data) => {
    const { value } = data;
    setSelectedSchool(value);
  };

  const handleOnSave = (event) => {
    event.preventDefault();
    const params = {
      id: itemData?.id,
      events_schools_id: selectedSchool
    };
    assignSchoolService(params)
      .then((response) => {
        toast.success('School assigned');
        onClose();
        onRefreshData();
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Error, can't update organism");
      });
  };

  const onRefreshData = () => {
    dispatch(setUpdate({ update: true }));
  };

  return (
    <SchoolAssignModalView
      schools={schools}
      organismsName={itemData?.name}
      selectedSchool={selectedSchool}
      onSchoolChange={handleOnSchoolChange}
      onSave={handleOnSave}
      onClose={onClose}
    />
  );
};

export default SchoolAssignModalComponent;
