/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';

/** Import styles section **/
import './SchoolDistributionStyles.scss';

/** Import helpers section **/

/** Import component section **/

const SchoolDistributionView = ({
  onConfirmAssignment,
  schools,
  getOrgBySchool,
  schoolsOrgs
}) => {
  return (
    <div className="school-dist">
      <div className="school-dist_item headers">
        <span>School</span>
        <span>MS</span>
        <span>HS</span>
        <Tooltip title="Status to allow assign delegates">
          <span>Ready</span>
        </Tooltip>
      </div>
      {schools?.map((schoolItem, index) => (
        <div className="school-dist_item" key={index}>
          <div className="school-dist_item_title">{schoolItem.school_name}</div>
          <div>
            {schoolItem.assigned_ms}/{schoolItem.ms_quantity}
          </div>
          <div>
            {schoolItem.assigned_hs}/{schoolItem.hs_quantity}
          </div>

          {schoolItem?.confirm_school_assigment ? (
            <Tooltip title="This school can assign delegates">
              <CheckCircleIcon className="school-dist_item_assign checked" />
            </Tooltip>
          ) : schoolItem.assigned_ms === schoolItem.ms_quantity &&
            schoolItem.assigned_hs === schoolItem.hs_quantity ? (
            <Tooltip title="Check to confirm school to assign delegates">
              <CheckCircleIcon
                className="school-dist_item_assign check-btn"
                onClick={(e) => onConfirmAssignment(schoolItem)}
              />
            </Tooltip>
          ) : (
            <RadioButtonUncheckedIcon className="school-dist_item_assign " />
          )}
        </div>
      ))}
    </div>
  );
};

//schoolItem.assigned_ms === schoolItem.ms_quantity

export default SchoolDistributionView;
