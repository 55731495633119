import request from '../api-connector';

export const getRegistrationRecordsService = () => {
  const serviceDef = {
    url: 'events-schools/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const validateRegistrationRecordService = ({ id, actual }) => {
  let url = 'events-schools/' + (actual === 1 ? 'deny' : 'validate');
  const serviceDef = {
    url: url,
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};
