/** Import react/libraries section **/
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/

/** Import services section **/
import {
  getAdvisorsService,
  deleteAdvisorService
} from '../../api/services/advisors-services';

/** Import component section **/
import AdvisorsView from './AdvisorsView';
import AdvisorModal from './AdvisorModal';
import ModalComponent from '../../components/Modal';

const AdvisorModel = {
  id: null,
  first_name: null,
  last_name: null,
  email: null
};

function AdvisorsComponent() {
  const [inProcess, setInProcess] = useState(false);
  const [advisors, setAdvisors] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalMode, setModalMode] = useState('');

  let assistanceConfirmed = useSelector(
    (store) => store.session?.profile?.confirm_assistance
  );

  const getAdvisors = useCallback(() => {
    setInProcess(true);
    getAdvisorsService()
      .then((response) => {
        setAdvisors(response);
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't load information");
        console.error('Error getting schools: ', err);
      })
      .finally(() => {
        setInProcess(false);
      });
  }, []);

  useEffect(() => {
    getAdvisors();
  }, [getAdvisors]);

  const handleOnAdd = () => {
    const newItem = JSON.parse(JSON.stringify(AdvisorModel));
    setSelectedItem(newItem);
    setModalMode('add');
  };

  const handleOnEdit = (row) => {
    setSelectedItem(row);
    setModalMode('edit');
  };

  const handleOnDelete = (row) => {
    setSelectedItem(row);
    setModalMode('delete');
  };

  const handleOnModalClose = () => {
    clearStates();
  };

  const handleOnDeleteConfirm = () => {
    deleteAdvisorService(selectedItem)
      .then((response) => {
        toast.success('Advisor deleted');
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Error, can't delete advisor");
        console.error('Error deleting advisor: ', err);
      })
      .finally(() => {
        clearStates();
        getAdvisors();
      });
  };

  const handleOnUpdateList = (data) => {
    clearStates();
    getAdvisors();
  };

  const clearStates = () => {
    setSelectedItem(null);
    setModalMode('');
  };

  return (
    <>
      <AdvisorsView
        inProcess={inProcess}
        data={advisors}
        assistanceConfirmed={assistanceConfirmed === 1 ? true : false}
        onAdd={handleOnAdd}
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
      />
      {selectedItem && (modalMode === 'add' || modalMode === 'edit') && (
        <AdvisorModal
          item={selectedItem}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedItem && modalMode === 'delete' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Delete advisor"
        >
          {'Are you sure to delete ' +
            selectedItem.first_name +
            ' ' +
            selectedItem.last_name +
            ' ?'}
        </ModalComponent>
      )}
    </>
  );
}

export default AdvisorsComponent;
