/** Import react/libraries section **/
import React from 'react';
import { Link } from 'react-router-dom';

/** Import helpers section **/

/** Import styles section **/
import './ChangePasswordStyles.scss';

/** Import resources section **/
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

/** Import component section **/
import MUITextField from '../../components/inputs/MUITextField';
import Logos from '../../components/Logos';

const ChangePasswordView = ({
  inProcess,
  password,
  passwordConf,
  email,
  onEmailChange,
  onPasswordChange,
  onPasswordConfChange,
  validForm,
  onSubmit,
  requested,
  successRequest
}) => {
  const formView = () => {
    return (
      <>
        <div className="changepassword_container_title">
          Please enter your new password
        </div>
        <div className="changepassword_container_form">
          <MUITextField
            label="Email"
            id="email"
            name="email"
            type="text"
            value={email}
            onChange={onEmailChange}
          />
          <MUITextField
            label="New Password"
            id="password"
            name="password"
            type="password"
            value={password}
            onChange={onPasswordChange}
          />
          <MUITextField
            label="Confirm password"
            id="confPassword"
            name="confPassword"
            type="password"
            value={passwordConf}
            onChange={onPasswordConfChange}
          />
        </div>
        <Button
          className="changepassword_container_button"
          onClick={onSubmit}
          disabled={!validForm}
        >
          {inProcess === true ? 'Sending...' : 'Send'}
        </Button>
        <div className="changepassword_container_link ">
          <Link to="/">
            <ArrowBackIosIcon /> Go to login
          </Link>
        </div>
      </>
    );
  };

  const errorView = () => {
    return (
      <>
        <span className="changepassword_container_title error">
          This request is not longer valid!
        </span>
      </>
    );
  };

  const viewToShow = () => {
    if (requested && !successRequest) {
      return errorView();
    }

    return formView();
  };

  return (
    <div className="changepassword">
      <Paper className="changepassword_container">
        {viewToShow()}
        <div className="changepassword_container_logos">
          <Logos />
        </div>
      </Paper>
    </div>
  );
};

export default ChangePasswordView;
