/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import './AdvisorModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import MUITextField from '../../../components/inputs/MUITextField';

const AdvisorModalView = ({ data, onInputChange, onClose, onSave }) => {
  return (
    <ModalComponent
      open={true}
      title={data?.id ? 'Edit advisor' : 'Add advisor'}
      onClose={onClose}
      onConfirm={onSave}
    >
      <div className="advisormodal">
        <MUITextField
          label="First Name"
          name="first_name"
          value={data?.first_name}
          type="text"
          onChange={onInputChange}
        />
        <MUITextField
          label="Last name"
          name="last_name"
          value={data?.last_name}
          type="text"
          onChange={onInputChange}
        />
        <MUITextField
          label="Email"
          name="email"
          value={data?.email}
          type="text"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default AdvisorModalView;
