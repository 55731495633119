import request from '../api-connector';

export const getAssignService = () => {
  const serviceDef = {
    url: 'organism/assign/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const createAssignService = ({
  organism_id,
  first_name,
  last_name,
  grade
}) => {
  const serviceDef = {
    url: 'organism/assign/create',
    method: 'post',
    params: { organism_id, first_name, last_name, grade }
  };
  return request(serviceDef);
};

export const updateAssignService = ({
  id,
  organism_id,
  first_name,
  last_name,
  grade
}) => {
  const serviceDef = {
    url: 'organism/assign/update',
    method: 'post',
    params: { id, organism_id, first_name, last_name, grade }
  };
  return request(serviceDef);
};

export const removeAssignService = ({ id }) => {
  const serviceDef = {
    url: 'organism/assign/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const confirmAssignService = () => {
  const serviceDef = {
    url: 'events-schools/confirm-finished-assignment',
    method: 'post',
    params: null
  };
  return request(serviceDef);
};
