import request from '../api-connector';

export const getEventConfigurationService = () => {
  const serviceDef = {
    url: 'events/read',
    method: 'post',
    params: null
  };
  return request(serviceDef);
};

export const getCountriesService = () => {
  const serviceDef = {
    url: 'countries/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const addCommitteeService = ({ name, code, level }) => {
  const serviceDef = {
    url: 'committee/create',
    method: 'post',
    params: { name, code, level }
  };
  return request(serviceDef);
};
export const updateCommitteeService = ({ id, name, code, level }) => {
  const serviceDef = {
    url: 'committee/update',
    method: 'post',
    params: { id, name, code, level }
  };
  return request(serviceDef);
};

export const deleteCommitteeService = ({ id }) => {
  const serviceDef = {
    url: 'committee/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};
export const addOrganismService = ({ name, priority, committee_id }) => {
  const serviceDef = {
    url: 'organism/create',
    method: 'post',
    params: { name, priority, committee_id }
  };
  return request(serviceDef);
};
export const updateOrganismService = ({ id, name, priority, committee_id }) => {
  const serviceDef = {
    url: 'organism/update',
    method: 'post',
    params: { id, name, priority, committee_id }
  };
  return request(serviceDef);
};

export const deleteOrganismService = ({ id }) => {
  const serviceDef = {
    url: 'organism/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const getAssignablesSchoolsService = ({ level }) => {
  const serviceDef = {
    url: 'events-schools/list-confirmed-assistance',
    method: 'get',
    params: { level }
  };
  return request(serviceDef);
};
export const getSchoolsDistService = () => {
  const serviceDef = {
    url: 'organism/assign/list-to-confirm',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const assignSchoolService = ({ id, events_schools_id }) => {
  const serviceDef = {
    url: 'organism/assign-event-school',
    method: 'post',
    params: { id, events_schools_id }
  };
  return request(serviceDef);
};

export const unassignSchoolService = ({ id }) => {
  const serviceDef = {
    url: 'organism/remove-assigned-event-school',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};
export const confirmSchoolAssigment = ({ id }) => {
  const serviceDef = {
    url: 'events-schools/confirm-school-assigment',
    method: 'post',
    params: { id }
  };
  console.log({ id });
  return request(serviceDef);
};
export const getEventInformation = () => {
  const serviceDef = {
    url: 'events/active-info',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};
export const getOrganizationDistribution = () => {
  const serviceDef = {
    url: 'organism/distribution',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};
