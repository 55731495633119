import request from '../api-connector';

export const getSchoolRegistryService = () => {
  const serviceDef = {
    url: 'events-schools/read',
    method: 'post',
    params: null
  };
  return request(serviceDef);
};

export const updateSchoolRegistryService = ({
  rfc,
  name,
  sat_name,
  phone,
  address1,
  address2,
  zip,
  city,
  states_id,
  ms_quantity,
  hs_quantity
}) => {
  const serviceDef = {
    url: 'events-schools/update',
    method: 'post',
    params: {
      rfc,
      name,
      sat_name,
      phone,
      address1,
      address2,
      zip,
      city,
      states_id,
      ms_quantity,
      hs_quantity
    }
  };
  return request(serviceDef);
};
