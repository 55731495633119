/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import services section **/
import { getEventInformation } from '../../../api/services/event-configuration-services';

/** Import helpers section **/
import { toast } from 'react-toastify';
// import { LOGIN_ROUTE } from '../../URL-routes';

/** Import component section **/
import EventInformationView from './EventInformationView';

const EventInformationComponent = ({ data }) => {
  const [eventInfo, setEventInfo] = useState([]);

  const getInfo = useCallback(() => {
    getEventInformation()
      .then((response) => {
        setEventInfo(response);
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't load information");
        console.error('Error getting schools: ', err);
      })
      .finally(() => {});
  }, []);

  useEffect(() => {
    getInfo();
  }, [getInfo, data]);
  return (
    <>
      <EventInformationView eventInfo={eventInfo} />
    </>
  );
};

export default EventInformationComponent;
