import request from '../api-connector';

export const getAdvisorsService = () => {
  const serviceDef = {
    url: 'advisors/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const addAdvisorService = ({ first_name, last_name, email }) => {
  const serviceDef = {
    url: 'advisors/create',
    method: 'post',
    params: { first_name, last_name, email }
  };
  return request(serviceDef);
};

export const updateAdvisorService = ({ id, first_name, last_name, email }) => {
  const serviceDef = {
    url: 'advisors/update',
    method: 'post',
    params: { id, first_name, last_name, email }
  };
  return request(serviceDef);
};

export const deleteAdvisorService = ({ id }) => {
  const serviceDef = {
    url: 'advisors/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};
