/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';

import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '120px',
    minWidth: '120px',
    // height: '65px',
    // minHeight: '65px',
    // padding: '0 5px',
    '& .MuiTextField-root': {
      // padding: '5px 0 0 0'
    },
    '& .MuiInputBase-adornedStart': {
      paddingLeft: '10px'
    },
    '& .MuiIconButton-root': {
      padding: '0'
    },
    '& .MuiInputLabel-root': {
      fontSize: '16px',
      // paddingBottom: '10px',
      color: theme.palette.normal.main
    }
  },
  datePicker: {},
  input: {
    padding: '10px 5px 10px 0',
    fontSize: '12px'
    // height: '12px'
    // backgroundColor: 'white'
  },
  invalid: {
    color: 'red'
  }
}));

export const MUIDateField = ({
  id,
  name,
  label,
  value,
  onChange,
  minDate = undefined,
  disabled,
  ...props
}) => {
  const classes = useStyles();
  const [date, setDate] = useState(null);
  const [valid, setValid] = useState(true);

  const handleOnDateChange = (value) => {
    setDate(value);
  };

  useEffect(() => {
    if (onChange) {
      if (date) {
        onChange(date.format('yyy-MM-DD'));
      } else {
        onChange(null);
      }
    }
  }, [date]);

  useEffect(() => {
    let newDate = null;
    let isValid = true;
    if (value) {
      if (moment.isMoment(value)) {
        newDate = value;
      } else {
        newDate = moment(value, ['DD/MM/yyy', 'yyy-MM-DD']);
        if (!newDate.isValid()) {
          isValid = false;
          newDate = null;
        }
      }
      setValid(isValid);
      setDate(newDate);
    }
  }, [value]);

  // const handleOnDateChange = (e) => {
  //   if (e && moment.isMoment(e)) {
  //     let newValue = e.format('yyy-MM-DD');
  //     onChange({ value: newValue, id: id, name: name });
  //   }
  // };

  // useEffect(() => {
  //   if (value === null || value === undefined) {
  //     setDate(null);
  //     return;
  //   }
  //   const newValue = moment.isMoment(value)
  //     ? value
  //     : moment(value, ['DD/MM/yyyy', 'yyyy-MM-DD']);

  //   if (!newValue.isSame(date)) {
  //     setDate(newValue);
  //   }
  // }, [value]);

  return (
    <div className={classes.root} id={`MUI_${id}`}>
      <KeyboardDatePicker
        className={classes.datePicker}
        autoOk
        id={id}
        label={label}
        value={value}
        onChange={handleOnDateChange}
        placeholder="DD/MM/YY"
        format="DD/MM/YY"
        InputAdornmentProps={{ position: 'start' }}
        disableToolbar
        variant="inline"
        disabled={disabled}
        minDate={disabled === true ? null : minDate}
        InputProps={{
          classes: {
            input: classes.input + (!valid ? ' ' + classes.invalid : '')
          }
        }}
        invalidDateMessage={null}
      />
    </div>
  );
};

MUIDateField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

export default MUIDateField;
