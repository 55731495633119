/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/** Import services section **/
import {
  getEventConfigurationService,
  getCountriesService
} from '../../api/services/event-configuration-services';

import { getEventReportService } from '../../api/services/reports-services';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/
import { setCountries } from '../../store/slices/countries-slice';
import { setUpdate } from '../../store/slices/refresh-slice';

/** Import component section **/
import EventConfigurationView from './EventConfigurationView';
import CommitteeModal from './CommitteeModal';

const CommitteeModel = {
  id: null,
  name: null,
  code: null,
  level: 1
};

const EventConfigurationComponent = () => {
  const [inProcess, setInProcess] = useState(false);
  const [eventData, setEventData] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [levelCommittees, setLevelCommittees] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalMode, setModalMode] = useState('');

  const dispatch = useDispatch();

  let updateData = useSelector((store) => store.refresh.update);

  const getEventConfiguration = useCallback(() => {
    setInProcess(true);
    getEventConfigurationService()
      .then((response) => {
        setEventData(response);
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't load information");
        console.error('Error getting data: ', err);
      })
      .finally(() => {
        setInProcess(false);
        clearStates();
        dispatch(setUpdate({ update: false }));
      });
  }, [dispatch]);

  useEffect(() => {
    if (updateData) getEventConfiguration();
  }, [updateData, getEventConfiguration]);

  const getCountries = useCallback(() => {
    getCountriesService()
      .then((response) => {
        dispatch(setCountries({ countries: response }));
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't load countries");
        console.error('Error getting countries: ', err);
      })
      .finally(() => {});
  }, [dispatch]);

  useEffect(() => {
    getEventConfiguration();
    getCountries();
  }, [getEventConfiguration, getCountries]);

  useEffect(() => {
    if (eventData) {
      const filteredCommittees = eventData?.committees.filter(
        (item) => item.level === selectedLevel + 1
      );
      setLevelCommittees(filteredCommittees);
    }
  }, [selectedLevel, eventData]);

  const handleOnLevelChange = (event, levelValue) => {
    setSelectedLevel(levelValue);
  };

  const handleOnAddCommittee = () => {
    const newItem = JSON.parse(JSON.stringify(CommitteeModel));
    newItem.level = selectedLevel + 1;
    setSelectedItem(newItem);
    setModalMode('add');
  };

  const handleOnEditCommittee = (committeeItem) => {
    const newItem = JSON.parse(JSON.stringify(committeeItem));
    newItem.level = selectedLevel + 1;
    setSelectedItem(newItem);

    setModalMode('edit');
  };

  const handleOnModalClose = () => {
    clearStates();
  };

  const clearStates = () => {
    setSelectedItem(null);
    setModalMode('');
  };

  const handleOnDownloadReport = () => {
    getEventReportService()
      .then((response) => {
        window.open(response?.filename, '_blank');
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Error, can't get report");
        console.error('Error getting report: ', err);
      })
      .finally(() => {});
  };

  return (
    <>
      <EventConfigurationView
        inProcess={inProcess}
        data={eventData}
        levelCommittees={levelCommittees}
        selectedLevel={selectedLevel}
        onLevelChange={handleOnLevelChange}
        onAddCommittee={handleOnAddCommittee}
        onEditCommittee={handleOnEditCommittee}
        onDownloadReport={handleOnDownloadReport}
      />
      {selectedItem && (modalMode === 'add' || modalMode === 'edit') && (
        <CommitteeModal item={selectedItem} onClose={handleOnModalClose} />
      )}
    </>
  );
};

export default EventConfigurationComponent;
