/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import services section **/
import {
  getPaymentService,
  confirmParticipationService,
  savePaymentImageService
} from '../../api/services/payment-services';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/

/** Import component section **/
import PaymentView from './PaymentView';
import ModalComponent from '../../components/Modal';
import DropFile from '../../components/DropFile';

const PaymentComponent = () => {
  const [confirmationCheck, setConfirmationCheck] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [openPaymentImageModal, setOpenPaymentImageModal] = useState(false);

  const getPayment = useCallback(() => {
    getPaymentService()
      .then((response) => {
        setPaymentData(response);
        setConfirmationCheck(response?.confirm_assistance ? true : false);
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't load information");
        console.error('Error getting payment data: ', err);
      })
      .finally(() => {});
  }, []);

  useEffect(() => {
    getPayment();
  }, [getPayment]);

  const handleOnConfirmationChange = () => {
    setOpenConfirmationModal(true);
  };

  const handleOnConfirmationModalClose = () => {
    setOpenConfirmationModal(false);
  };

  const handleOnConfirmationConfirm = () => {
    const actualValue = paymentData.confirm_assistance;

    confirmParticipationService()
      .then((response) => {
        setConfirmationCheck(!actualValue);
        toast.success('Confirmation successly changed');
        getPayment();
      })
      .catch((err) => {
        setConfirmationCheck(actualValue);
        if (err?.response_status) toast.error("Can't change confirmation");
        console.error('Error getting confirm data: ', err);
      })
      .finally(() => {
        setOpenConfirmationModal(false);
      });
  };

  const handleOnLoad = () => {
    setOpenPaymentImageModal(true);
  };

  const handleOnClosePaymentImageModal = () => {
    setOpenPaymentImageModal(false);
  };

  const handleOnSavePaymentImageModal = (files) => {
    const data = {
      file: files[0]
    };
    savePaymentImageService(data).then((response) => {
      getPayment();
      toast.success('Image saved');
    });
    setOpenPaymentImageModal(false);
  };

  return (
    <>
      <PaymentView
        data={paymentData}
        confirmationCheck={confirmationCheck}
        onConfirmationChange={handleOnConfirmationChange}
        onLoad={handleOnLoad}
      />
      {openPaymentImageModal && (
        <DropFile
          open={true}
          title={'Load payment image'}
          url={paymentData?.payment_file_address}
          onConfirm={handleOnSavePaymentImageModal}
          onClose={handleOnClosePaymentImageModal}
          confirmText="Yes"
          cancelText="No"
        ></DropFile>
      )}
      {openConfirmationModal && (
        <ModalComponent
          open={true}
          onClose={handleOnConfirmationModalClose}
          onConfirm={handleOnConfirmationConfirm}
          title="Participation confirm"
        >
          <>
            <div>Are you sure to confirm participation?.</div>
            <div className="events_alerttext">This action can't be undone.</div>
          </>
        </ModalComponent>
      )}
    </>
  );
};

export default PaymentComponent;
