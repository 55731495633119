export const formatNumberToCurrency = (
  number = 0,
  decPlaces = 0,
  thouSeparator = ',',
  decSeparator = '.'
) => {
  return '$ ' + formatNumber(number, decPlaces, thouSeparator, decSeparator);
};

export const formatNumber = (
  number = 0,
  decPlaces = 0,
  thouSeparator = ',',
  decSeparator = '.'
) => {
  if (isNaN(number)) return '0';
  var sign = number < 0 ? '-' : '';
  var i = parseInt((number = Math.abs(+number || 0).toFixed(decPlaces))) + '';
  // eslint-disable-next-line no-use-before-define
  var j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    sign +
    (j ? i.substr(0, j) + thouSeparator : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thouSeparator) +
    (decPlaces
      ? decSeparator +
        Math.abs(number - i)
          .toFixed(decPlaces)
          .slice(2)
      : '')
  );
};
