/** Import react/libraries section **/
import { createSlice } from '@reduxjs/toolkit';
import asynkstorage from 'asynkstorage';

const initialState = {
  loggedIn: false,
  token: '',
  role: 0,
  profile: {
    name: '',
    school_name: '',
    confirm_assistance: 0
  }
};

const sessionSlice = createSlice({
  name: 'session',
  initialState: initialState,
  reducers: {
    setSession: (state, { payload: { token, role, profile } }) => {
      state.token = token;
      state.role = role;
      state.profile = profile;
      state.loggedIn = true;

      storeToken({ token });
      storeRole({ role });
      storeProfile(profile);
    },
    clearSession: (state) => {
      state.token = initialState.token;
      state.role = initialState.role;
      state.profile = initialState.profile;
      state.loggedIn = initialState.loggedIn;
      localStorage.clear();
    }
  }
});
export default sessionSlice.reducer;

export const { setSession, clearSession } = sessionSlice.actions;

// LOCAL STORAGE MANAGE FUNCTIONS

export const getTokenStored = new Promise((resolve, reject) => {
  asynkstorage
    .getItem('token')
    .then((token) => {
      return resolve(token);
    })
    .catch((e) => {
      return resolve(null);
    });
});

export async function storeToken({ token }) {
  const response = new Promise((resolve, reject) => {
    asynkstorage
      .setItem('token', token)
      .then(() => {
        return resolve(true);
      })
      .catch((e) => {
        console.error('storeToken error: ', e.message);
        return reject(false);
      });
  });

  return response;
}

export async function storeRole({ role }) {
  const response = new Promise((resolve, reject) => {
    asynkstorage
      .setItem('role', role)
      .then(() => {
        return resolve(true);
      })
      .catch((e) => {
        console.error('storeRole error: ', e.message);
        return reject(false);
      });
  });
  return response;
}

export async function storeProfile(profile) {
  const response = new Promise((resolve, reject) => {
    asynkstorage
      .setItem('profile', JSON.stringify(profile))
      .then(() => {
        return resolve(true);
      })
      .catch((e) => {
        console.error('storeProfile error: ', e.message);
        return reject(false);
      });
  });
  return response;
}

export async function clearLocalStorage() {
  const response = new Promise((resolve, reject) => {
    asynkstorage
      .removeItem('token')
      .then((token) => {
        asynkstorage
          .removeItem('role')
          .then((role) => {
            asynkstorage
              .removeItem('profile')
              .then((profile) => {
                return resolve(true);
              })
              .catch((e) => {
                return resolve(false);
              });
          })
          .catch((e) => {
            return resolve(false);
          });
      })
      .catch((e) => {
        return resolve(false);
      });
  });
  return response;
}
