/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import services section **/
import {
  confirmSchoolAssigment,
  getSchoolsDistService
} from '../../../api/services/event-configuration-services';

/** Import helpers section **/
import { toast } from 'react-toastify';
// import { LOGIN_ROUTE } from '../../URL-routes';

/** Import component section **/
import ModalComponent from '../../../components/Modal/ModalComponent';
import SchoolDistributionView from './SchoolDistributionView';

const SchoolDistributionComponent = ({ data }) => {
  const [schools, setSchools] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const getSchools = useCallback(() => {
    getSchoolsDistService()
      .then((response) => {
        setSchools(response);
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't load information");
        console.error('Error getting schools: ', err);
      })
      .finally(() => {});
  }, []);

  useEffect(() => {
    getSchools();
  }, [getSchools, data]);

  const handleOnConfirmAssignment = (item) => {
    setSelectedItem(item);
  };

  const handleOnModalClose = () => {
    setSelectedItem(null);
  };

  const handleOnConfirmAssignmentConfirm = () => {
    const params = {
      id: selectedItem?.id
    };
    confirmSchoolAssigment(params)
      .then((response) => {
        toast.success('School status changed');
        getSchools();
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't change status");
        console.error('Error changing status: ', err);
      })
      .finally(() => {
        setSelectedItem(null);
      });
  };

  return (
    <>
      <SchoolDistributionView
        schools={schools}
        onConfirmAssignment={handleOnConfirmAssignment}
      />
      {selectedItem && (
        <ModalComponent
          open={selectedItem}
          onClose={handleOnModalClose}
          onConfirm={handleOnConfirmAssignmentConfirm}
          title="Delete committee"
        >
          {'Are you sure to confirm school ' +
            selectedItem?.school_name +
            ' to assign delegates?'}
        </ModalComponent>
      )}
    </>
  );
};

export default SchoolDistributionComponent;
