/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import './SchoolModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import MUITextField from '../../../components/inputs/MUITextField';
import MUISelect from '../../../components/inputs/MUISelect';

const SchoolModalView = ({ data, states, onInputChange, onClose, onSave }) => {
  return (
    <ModalComponent
      open={true}
      title={data?.id ? 'Edit school' : 'Add school'}
      onClose={onClose}
      onConfirm={onSave}
      width={800}
    >
      <div className="schoolmodal">
        <MUITextField
          label="School *"
          name="name"
          value={data?.name}
          type="text"
          onChange={onInputChange}
        />
        <MUITextField
          label="Official Name SAT"
          name="sat_name"
          value={data?.sat_name}
          type="text"
          onChange={onInputChange}
        />
        <MUITextField
          label="RFC"
          name="rfc"
          value={data?.rfc}
          type="text"
          onChange={onInputChange}
        />

        <MUITextField
          label="Address 1"
          name="address1"
          value={data?.address1}
          type="text"
          onChange={onInputChange}
        />
        <MUITextField
          label="Address 2"
          name="address2"
          value={data?.address2}
          type="text"
          onChange={onInputChange}
        />
        <MUITextField
          label="Zip"
          name="zip"
          value={data?.zip}
          type="text"
          onChange={onInputChange}
        />

        <MUISelect
          label="States"
          name="states_id"
          id="states_id"
          items={states}
          idSelector="id"
          selector="name"
          defaultValue={data?.states_id}
          noSelectedLabel="Select"
          onChange={onInputChange}
        />
        <MUITextField
          label="City"
          name="city"
          value={data?.city}
          type="text"
          onChange={onInputChange}
        />
        <MUITextField
          label="Phone"
          name="phone"
          value={data?.phone}
          type="text"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default SchoolModalView;
