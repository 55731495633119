/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import './EventModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import MUITextField from '../../../components/inputs/MUITextField';
import MUIDateField from '../../../components/inputs/MUIDateField';

const EventModalView = ({ data, onInputChange, onClose, onSave }) => {
  return (
    <ModalComponent
      open={true}
      title={data?.id ? 'Edit event' : 'Add event'}
      onClose={onClose}
      onConfirm={onSave}
    >
      <div className="eventmodal">
        <MUITextField
          label="Title"
          name="name"
          value={data?.name}
          type="text"
          onChange={onInputChange}
        />
        <MUIDateField
          id="date"
          name="date"
          label="Start date"
          value={data?.date}
          onChange={(value) => onInputChange({ name: 'date', value: value })}
        />

        <MUITextField
          label="Price"
          name="event_price"
          value={data?.event_price}
          type="number"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default EventModalView;
