/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import services section **/
import {
  addAdvisorService,
  updateAdvisorService
} from '../../../api/services/advisors-services';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/

/** Import component section **/
import AdvisorModalView from './AdvisorModalView';

const AdvisorModalComponent = ({ item, onClose, onUpdateList }) => {
  const [itemData, setItemData] = useState({});

  useEffect(() => {
    setItemData(item);
  }, [item]);

  const handleOnInputChange = (data) => {
    const { value, name } = data;
    setItemData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnSave = (event) => {
    event.preventDefault();
    if (itemData.id) {
      updateAdvisorService(itemData)
        .then((response) => {
          console.log(response);
          toast.success('Advisor updated');
          onUpdateList();
        })
        .catch((err) => {
          if (err?.response_status) toast.error("Error, can't update");
        });
    } else {
      addAdvisorService(itemData)
        .then(() => {
          toast.success('Advisor added');
          onUpdateList();
        })
        .catch((err) => {
          if (err?.response_status) toast.error("Error, can't save");
        });
    }
  };

  return (
    <AdvisorModalView
      data={itemData}
      onInputChange={handleOnInputChange}
      onSave={handleOnSave}
      onClose={onClose}
    />
  );
};

export default AdvisorModalComponent;
