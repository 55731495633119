/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/


/** Import styles section **/
import './EventInformationStyles.scss';

/** Import helpers section **/

/** Import component section **/

const EventInformationView = ({ eventInfo }) => {
  return (
    <div className="event-info">
      <div className="event-info_date">Start date: {eventInfo?.date} </div>
      <div className="event-info_container">
        <span>Schools: {eventInfo?.schools} </span>
        <span>Students: {eventInfo?.students}</span>
      </div>
    </div>
  );
};

export default EventInformationView;
