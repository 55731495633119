/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

/** Import services section **/
import { getSchoolsService } from '../../api/services/schools-services';
import { registrationService } from '../../api/services/registration-services';

/** Import helpers section **/
import { toast } from 'react-toastify';
import { LOGIN_ROUTE } from '../../URL-routes';

/** Import component section **/
import RegistrationView from './RegistrationView';
import ConfirmationModal from '../../components/ConfirmationModal';

const RegistrationComponent = () => {
  const [inProcess, setInProcess] = useState(false);
  const [registered, setRegistrationed] = useState(false);
  const [schools, setSchools] = useState([]);
  const [selected, setSelected] = useState(null);

  const history = useHistory();

  const getSchools = useCallback(() => {
    getSchoolsService()
      .then((response) => {
        setSchools(response);
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't load information");
        console.error('Error getting schools: ', err);
      })
      .finally(() => {
        setInProcess(false);
      });
  }, []);

  useEffect(() => {
    getSchools();
  }, [getSchools]);

  const handleOnSubmit = (userData) => {
    setInProcess(true);
    userData.school_id = selected;

    registrationService(userData)
      .then((response) => {
        setRegistrationed(true);
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't load information");
        console.error('Error in registration: ', err);
      })
      .finally(() => {
        setInProcess(false);
      });
  };

  const onSchoolChange = (value) => {
    setSelected(value);
  };

  const handleOnConfirmModalClose = () => {
    setRegistrationed(false);
    history.push(LOGIN_ROUTE);
  };

  return (
    <>
      <RegistrationView
        onSubmit={handleOnSubmit}
        inProcess={inProcess}
        registered={registered}
        schools={schools}
        onSchoolChange={onSchoolChange}
      />
      {registered && (
        <ConfirmationModal
          title={'Successfully registered!'}
          message={'An email with the verification link was sent.'}
          onClose={handleOnConfirmModalClose}
        />
      )}
    </>
  );
};

export default RegistrationComponent;
