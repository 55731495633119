/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import helpers section **/
import { formatNumberToCurrency } from '../../utils/formats';

/** Import styles section **/
import './DashboardStyles.scss';

/** Import resources section **/
import GetAppIcon from '@material-ui/icons/GetApp';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';
import ActiveCell from '../../components/ActiveCell';

const DashboardView = ({
	data,
	inProcess,
	onValidateStatus,
	onDownloadImage,
	onConfirmAssistance
}) => {
	const PaymentImageCell = ({ item }) => {
		if (item.payment_file_address) {
			return (
				<a
					href={item?.payment_file_address}
					target="_blank"
					rel="noreferrer"
				>
					<GetAppIcon className="dashboard_paymentimage" />
				</a>
			);
		}
		return <></>;
	};

	const AmountCell = ({ amount }) => {
		return (
			<div className={'dashboard_amount'}>
				{formatNumberToCurrency(amount, 2)}
			</div>
		);
	};

	const tableColumns = useMemo(
		() => [
			{
				name: 'id',
				selector: 'id',
				omit: true
			},
			{
				name: 'School',
				selector: (row) => row?.school?.name,
				sortable: true,
				center: true,
				grow: 2
			},
			{
				name: 'MS Students',
				selector: (row) => row?.students_ms,
				sortable: true,
				center: true
			},
			{
				name: 'HS Students',
				selector: (row) => row?.students_hs,
				sortable: true,
				center: true
			},
			{
				name: 'Advisors',
				selector: (row) => row?.advisors_count,
				sortable: true,
				center: true
			},
			{
				name: 'Observers',
				selector: (row) => row?.observers_count,
				sortable: true,
				center: true
			},
			{
				name: 'Payment',
				selector: (row) => row?.total,
				cell: (row) => <AmountCell amount={row?.total} />,
				sortable: true,
				center: true
			},
			{
				name: 'Payment receipt',
				selector: (row) => (row?.payment_file_address ? 1 : 0),
				cell: (row) => <PaymentImageCell item={row} />,
				sortable: true,
				center: true,
				width: '80px'
			},
			// {
			//   name: 'Registered',
			//   selector: 'register_done',
			//   cell: (row) => <ActiveCell active={row.register_done} />,
			//   sortable: true,
			//   center: true,
			//   width: '80px'
			// },
			{
				name: '|',
				width: '40px',
				center: true
			},
			{
				name: 'Confirmed',
				selector: 'confirm_assistance',
				cell: (row) => (
					<ActiveCell
						active={row.confirm_assistance}
						onSwitchActive={() =>
							onConfirmAssistance(row, 'assistance')
						}
					/>
				),
				sortable: true,
				center: true,
				width: '80px'
			},

			{
				name: 'Paid',
				selector: 'payment_done',
				cell: (row) => (
					<ActiveCell
						active={row.payment_done}
						onSwitchActive={() => onValidateStatus(row, 'payment')}
					/>
				),
				sortable: true,
				center: true,
				width: '80px'
			},
			{
				name: 'Commitees assigned',
				selector: 'confirm_school_assigment',
				cell: (row) => (
					<ActiveCell active={row.confirm_school_assigment} />
				),
				sortable: true,
				center: true,
				width: '80px'
			},
			{
				name: 'Delegates assigned',
				selector: 'confirm_finish_school_assigment',
				cell: (row) => (
					<ActiveCell active={row.confirm_finish_school_assigment} />
				),
				sortable: true,
				center: true,
				width: '80px'
			}
		],
		[onValidateStatus, onConfirmAssistance]
	);

	return (
		<>
			<PageTitle title="Event Dashboard" />
			<DataTable data={data} columns={tableColumns} loading={inProcess} />
		</>
	);
};

export default DashboardView;
