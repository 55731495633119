/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

/** Import services section **/
import { getStatesService } from '../../api/services/catalogues-services';
import {
  getSchoolRegistryService,
  updateSchoolRegistryService
} from '../../api/services/school-registry-services';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/

/** Import component section **/
import SchoolRegistryView from './SchoolRegistryView';

const registryModel = {
  rfc: null,
  name: null,
  phone: null,
  state_id: -1,
  city: null,
  address1: null,
  address2: null,
  ms_quantity: 0,
  hs_quantity: 0
};

const SchoolRegistryComponent = () => {
  const [inProcess, setInProcess] = useState(false);
  const [loadFinished, setLoadFinished] = useState(false);
  const [registryData, setRegistryData] = useState(registryModel);
  const [states, setStates] = useState([]);

  let assistanceConfirmed = useSelector(
    (store) => store.session?.profile?.confirm_assistance
  );

  const getRegistry = useCallback(() => {
    setInProcess(true);
    setLoadFinished(false);
    getSchoolRegistryService()
      .then((response) => {
        let info = response.school;
        info['ms_quantity'] = response.ms_quantity;
        info['hs_quantity'] = response.hs_quantity;
        setRegistryData(info);
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't load information");
        console.error('Error getting registry data: ', err);
      })
      .finally(() => {
        setLoadFinished(true);
        setInProcess(false);
      });
  }, []);

  const getData = useCallback(() => {
    setInProcess(true);
    getStatesService()
      .then((response) => {
        setStates(response);
        getRegistry();
      })
      .catch((err) => {
        setInProcess(false);
        if (err?.response_status) toast.error("Can't load information");
        console.error('Error getting states: ', err);
      });
  }, [getRegistry]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleOnInputChange = (data) => {
    const { value, name } = data;
    setRegistryData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnSave = (event) => {
    event.preventDefault();
    updateSchoolRegistryService(registryData)
      .then(() => {
        toast.success('School updated');
        getRegistry();
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Error, can't update");
      });
  };

  return (
    <SchoolRegistryView
      inProcess={inProcess}
      loadFinished={loadFinished}
      data={registryData}
      assistanceConfirmed={assistanceConfirmed === 1 ? true : false}
      states={states}
      onInputChange={handleOnInputChange}
      onSave={handleOnSave}
    />
  );
};

export default SchoolRegistryComponent;
