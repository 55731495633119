/** Import react/libraries section **/
import React from 'react';

/** Import styles section **/
import './OrganismStyles.scss';

/** Import resources section **/
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SchoolIcon from '@material-ui/icons/School';
import PersonIcon from '@material-ui/icons/Person';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

export const OrganismComponent = ({
  priority,
  name,
  school,
  student,
  onEditOrganism,
  onDeleteOrganism,
  onAssign,
  onRemoveAssign
}) => {
  return (
    <div className="organism">
      <div className="organism_priority">
        {priority ? (
          <CheckCircleIcon className="organismicon organism_priority check" />
        ) : (
          <RadioButtonUncheckedIcon className="organismicon organism_priority uncheck" />
        )}
      </div>
      <div className="organismitem" onClick={onEditOrganism}>
        <span className="organism_title">{name}</span>
        <RemoveCircleIcon
          className="organismicon remove"
          onClick={onDeleteOrganism}
        />
      </div>
      <div className="organismitem" onClick={onAssign}>
        <span className="organism_school">{school?.name}</span>
        {school?.name?.length > 0 ? (
          <RemoveCircleIcon
            className="organismicon remove"
            onClick={onRemoveAssign}
          />
        ) : (
          <SchoolIcon className="organismicon school" />
        )}
      </div>
      <div className="organismitem">
        {student ? (
          <span className="organism_student">
            {student?.first_name + ' ' + student?.last_name}
          </span>
        ) : (
          <span></span>
        )}
        <PersonIcon className="organismicon student" />
      </div>
    </div>
  );
};

export default OrganismComponent;
