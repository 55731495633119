/** Import react/libraries section **/
import React from 'react';

/** Import styles section **/
import './OrganismModalStyles.scss';

/** Import resources section **/
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import MUITextField from '../../../components/inputs/MUITextField';
import MUIAutocomplete from '../../../components/inputs/MUIAutocomplete';
import MUICheckBox from '../../../components/inputs/MUICheckBox';

const OrganismModalView = ({
  data,
  countries,
  selectedCountry,
  selectByCountry,
  onSelectByCountryChange,
  onPriorityChange,
  onCountryChange,
  onInputChange,
  onClose,
  onSave
}) => {
  return (
    <ModalComponent
      open={true}
      title={data?.id ? 'Edit organization' : 'Add organization'}
      onClose={onClose}
      onConfirm={onSave}
    >
      <div className="organismModal">
        {selectByCountry ? (
          <RadioButtonCheckedIcon
            className="organismModal_priority checked"
            onClick={onSelectByCountryChange}
          />
        ) : (
          <RadioButtonUncheckedIcon
            className="organismModal_priority"
            onClick={onSelectByCountryChange}
          />
        )}
        <MUIAutocomplete
          id="country"
          name="name"
          options={countries}
          label="Country"
          selector="name"
          value={selectedCountry}
          getOptionLabel={(option) => option.name}
          onChange={onCountryChange}
          disabled={!selectByCountry}
        />

        {!selectByCountry ? (
          <RadioButtonCheckedIcon
            className="organismModal_priority checked"
            onClick={onSelectByCountryChange}
          />
        ) : (
          <RadioButtonUncheckedIcon
            className="organismModal_priority"
            onClick={onSelectByCountryChange}
          />
        )}

        <MUITextField
          label="Title"
          name="name"
          value={!selectByCountry ? data?.name : null}
          type="text"
          onChange={onInputChange}
          disabled={selectByCountry}
        />
        <span />
        <MUICheckBox
          label="Priority organization"
          checked={data?.priority ? true : false}
          onChange={onPriorityChange}
        />
      </div>
    </ModalComponent>
  );
};

export default OrganismModalView;
