import request from '../api-connector';

export const getDashboardService = () => {
	const serviceDef = {
		url: 'dashboard/list',
		method: 'get',
		params: null
	};
	return request(serviceDef);
};

export const validatePaymentStatusService = ({ id }) => {
	const serviceDef = {
		url: 'events-schools/paid',
		method: 'post',
		params: { id }
	};
	return request(serviceDef);
};
export const confirmAssistanceDashboadService = ({ id }) => {
	const serviceDef = {
		url: 'events-schools/dashboard/confirm-assistance',
		method: 'post',
		params: { id }
	};
	return request(serviceDef);
};
