/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import helpers section **/

/** Import styles section **/
import './RegistrationRecordsStyles.scss';

/** Import resources section **/

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';
import ActiveCell from '../../components/ActiveCell';

const RegistrationRecordsView = ({ inProcess, data, onValidate }) => {
  const tableColumns = useMemo(
    () => [
      {
        name: 'id',
        selector: 'id',
        omit: true
      },
      {
        name: 'First Name',
        selector: (row) => row?.user?.first_name,
        sortable: true,
        center: true
      },
      {
        name: 'Last name',
        selector: (row) => row?.user?.last_name,
        sortable: true,
        center: true
      },
      {
        name: 'Email',
        selector: (row) => row?.user?.email,
        sortable: true,
        center: true
      },
      {
        name: 'School',
        selector: (row) => row?.school?.name,
        sortable: true,
        center: true
      },
      {
        name: 'Validated',
        selector: 'validate',
        cell: (row) => (
          <ActiveCell
            active={row.validate}
            onSwitchActive={() => onValidate(row)}
          />
        ),
        sortable: true,
        center: true
      }
    ],
    [onValidate]
  );

  return (
    <>
      <PageTitle title="Event registrations" />
      <DataTable data={data} columns={tableColumns} loading={inProcess} />
    </>
  );
};

export default RegistrationRecordsView;
