import request from '../api-connector';

export const getEventsService = () => {
  const serviceDef = {
    url: 'events/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const addEventService = ({ name, date, event_price }) => {
  const serviceDef = {
    url: 'events/create',
    method: 'post',
    params: { name, date, event_price }
  };
  return request(serviceDef);
};

export const updateEventService = ({ id, name, date, event_price }) => {
  const serviceDef = {
    url: 'events/update',
    method: 'post',
    params: { id, name, date, event_price }
  };
  return request(serviceDef);
};

export const deleteEventService = ({ id }) => {
  const serviceDef = {
    url: 'events/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const activeEventService = ({ id, active }) => {
  const serviceDef = {
    url: 'events/active',
    method: 'post',
    params: { id, active }
  };
  return request(serviceDef);
};
