/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import services section **/
import { getOrganizationDistribution } from '../../../api/services/event-configuration-services';

/** Import helpers section **/
import { toast } from 'react-toastify';
// import { LOGIN_ROUTE } from '../../URL-routes';

/** Import component section **/
import OrganizationDistributionView from './OrganizationDistributionView';

const OrganizationDistributionComponent = ({ data }) => {
  const [organizationDist, setOrganizationDist] = useState([]);

  const getDistribution = useCallback(() => {
    getOrganizationDistribution()
      .then((response) => {
        setOrganizationDist(response);
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't load information");
        console.error('Error getting schools: ', err);
      })
      .finally(() => {});
  }, []);

  useEffect(() => {
    getDistribution();
  }, [getDistribution, data]);
  return (
    <>
      <OrganizationDistributionView organizationDist={organizationDist} />
    </>
  );
};

export default OrganizationDistributionComponent;
