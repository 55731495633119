/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/

/** Import styles section **/
import './OrganizationDistributionStyles.scss';

/** Import helpers section **/

/** Import component section **/

const OrganizationDistributionView = ({ organizationDist }) => {
  return (
    <div className="organization-dist">
      {organizationDist?.map((OrgItem, index) => (
        <div className="organization-dist_organization" key={index}>
          <span className="organization-dist_organization_name">
            {OrgItem.name}
          </span>
          <span className="organization-dist_organization_count">
            {OrgItem.count}
          </span>
        </div>
      ))}
    </div>
  );
};

export default OrganizationDistributionView;
