/** Import react/libraries section **/
import React, { useState, useEffect, useCallback } from 'react';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/

/** Import services section **/
import {
  getRegistrationRecordsService,
  validateRegistrationRecordService
} from '../../api/services/registration-records-services';

/** Import component section **/
import RegistrationRecordsView from './RegistrationRecordsView';
import ModalComponent from '../../components/Modal';

function RegistrationRecordsComponent() {
  const [inProcess, setInProcess] = useState(false);
  const [registrationRecords, setRegistrationRecords] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalMode, setModalMode] = useState('');

  const getRegistrationRecords = useCallback(() => {
    setInProcess(true);
    getRegistrationRecordsService()
      .then((response) => {
        setRegistrationRecords(response);
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't load information");
        console.error('Error getting registrations: ', err);
      })
      .finally(() => {
        setInProcess(false);
      });
  }, []);

  useEffect(() => {
    getRegistrationRecords();
  }, [getRegistrationRecords]);

  const handleOnModalClose = () => {
    clearStates();
  };

  const handleOnValidate = (row) => {
    setSelectedItem(row);
    setModalMode('validate');
  };

  const handleOnValidateConfirm = () => {
    let params = {
      id: selectedItem.id,
      actual: selectedItem.validate
    };
    validateRegistrationRecordService(params)
      .then((response) => {
        toast.success('Registration status changed');
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Error, can't change status");
        console.error('Error changing status: ', err);
      })
      .finally(() => {
        clearStates();
        getRegistrationRecords();
      });
  };

  const clearStates = () => {
    setSelectedItem(null);
    setModalMode('');
  };

  return (
    <>
      <RegistrationRecordsView
        inProcess={inProcess}
        data={registrationRecords}
        onValidate={handleOnValidate}
      />
      {selectedItem && modalMode === 'validate' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnValidateConfirm}
          title="Validate registration"
        >
          {'Are you sure to ' +
            (selectedItem.validate ? 'invalidate' : 'validate') +
            ' registration?'}
        </ModalComponent>
      )}
    </>
  );
}

export default RegistrationRecordsComponent;
