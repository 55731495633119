/** Import react/libraries section **/
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

/** Import styles section **/
import './OrganismStyles.scss';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/
import {
  deleteOrganismService,
  unassignSchoolService
} from '../../../api/services/event-configuration-services';
import { setUpdate } from '../../../store/slices/refresh-slice';

/** Import components section **/
import OrganismView from './OrganismView';
import ModalComponent from '../../../components/Modal/ModalComponent';
import OrganismModal from '../OrganismModal';
import SchoolAssignModal from '../SchoolAssignModal';

export const OrganismComponent = ({ data, level }) => {
  const [modalMode, setModalMode] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  let dispatch = useDispatch();

  const handleOnEditOrganism = () => {
    const newItem = JSON.parse(JSON.stringify(data));
    setSelectedItem(newItem);
    setModalMode('editOrganism');
  };

  const handleOnDeleteOrganism = (event) => {
    event.stopPropagation();
    setModalMode('deleteOrganism');
  };

  const handleOnAssign = (event) => {
    const newItem = JSON.parse(JSON.stringify(data));
    newItem.level = level;
    setSelectedItem(newItem);
    setModalMode('assignSchool');
  };

  const handleOnModalClose = () => {
    setModalMode('');
  };

  const handleOnDeleteOrganismConfirm = () => {
    deleteOrganismService(data)
      .then((response) => {
        toast.success('Organization deleted');
        onRefreshData();
      })
      .catch((err) => {
        if (err?.response_status)
          toast.error("Error, can't delete organization");
        console.error('Error deleting organization: ', err);
      })
      .finally(() => {
        setModalMode('');
      });
  };

  const handleOnRemoveAssign = (event) => {
    event.stopPropagation();
    setModalMode('unassignSchool');
  };

  const handleOnUnassignConfirm = () => {
    unassignSchoolService({ id: data?.id })
      .then((response) => {
        toast.success('School unassigned');
        onRefreshData();
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Error, can't unassign school");
        console.error('Error unassigning school: ', err);
      })
      .finally(() => {
        setModalMode('');
      });
  };

  const onRefreshData = () => {
    dispatch(setUpdate({ update: true }));
  };

  return (
    <>
      <OrganismView
        priority={data?.priority}
        name={data?.name}
        school={data?.school}
        student={data?.assigned}
        onEditOrganism={handleOnEditOrganism}
        onDeleteOrganism={handleOnDeleteOrganism}
        onAssign={handleOnAssign}
        onRemoveAssign={handleOnRemoveAssign}
      />
      {selectedItem && modalMode === 'editOrganism' && (
        <OrganismModal item={selectedItem} onClose={handleOnModalClose} />
      )}
      {modalMode === 'deleteOrganism' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteOrganismConfirm}
          title="Delete organism"
        >
          {'Are you sure to delete the organism ' + data?.name + ' ?'}
        </ModalComponent>
      )}

      {selectedItem && modalMode === 'assignSchool' && (
        <SchoolAssignModal item={selectedItem} onClose={handleOnModalClose} />
      )}
      {modalMode === 'unassignSchool' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnUnassignConfirm}
          title="Unassign school"
        >
          {'Are you sure to unassign the school of ' + data?.name + ' ?'}
        </ModalComponent>
      )}
    </>
  );
};

export default OrganismComponent;
