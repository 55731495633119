/** Import react/libraries section **/
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

/** Import resources section **/
import { Button, Paper, TextField } from '@material-ui/core';

/** Import styles section **/
import './LoginStyles.scss';

/** Import helpers section **/
import { CHANGE_PASSWORD_REQUEST_ROUTE } from '../../URL-routes';

/** Import component section **/
import Logos from '../../components/Logos';

const LoginView = ({ username, password, onSubmit, inProcess }) => {
  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange'
  });

  const handleOnSubmit = (data) => {
    onSubmit(data);
  };

  return (
    <div className="login">
      <Paper className="login_container">
        <form
          className="login_container_form"
          onSubmit={handleSubmit(handleOnSubmit)}
        >
          <TextField
            label="Email"
            id="username"
            name="username"
            type="text"
            variant="outlined"
            {...register('username', {
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
            })}
          />

          <TextField
            label="Password"
            id="password"
            name="password"
            type="password"
            variant="outlined"
            {...register('password', {
              required: true,
              message: 'Contraseña requerida'
            })}
          />

          <div className="login_container_form-button">
            <Button
              onClick={handleSubmit}
              type="submit"
              disabled={!formState.isValid}
            >
              {inProcess === true ? 'Accessing...' : 'Login'}
            </Button>
          </div>
        </form>

        <div className="login_container_recovery">
          <div className="login_container_recovery-left">
            <p>
              Forgot password?,{' '}
              <Link to={CHANGE_PASSWORD_REQUEST_ROUTE}> click here </Link>
            </p>
            <div className="login_container_recovery-left-divider" />
            <Link to="/registration">Registration</Link>
          </div>
        </div>
        <div className="login_container_logos">
          <Logos />
        </div>
      </Paper>
    </div>
  );
};

export default LoginView;
