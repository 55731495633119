/** Import react/libraries section **/
import React from 'react';
import { Link } from 'react-router-dom';

/** Import helpers section **/

/** Import styles section **/
import './ChangePasswordRequestStyles.scss';

/** Import resources section **/
import { Button, Paper } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

/** Import services section **/

/** Import component section **/
import MUITextField from '../../components/inputs/MUITextField';
import Logos from '../../components/Logos';

const ChangePasswordRequestView = ({
  email,
  onEmailChange,
  validEmail,
  requested,
  inProcess,
  successRequest,
  onSubmit,
  onResetRequest
}) => {
  const requestView = () => {
    return (
      <>
        <div className="changepasswordreq_container_form">
          <MUITextField
            label="Enter your email address"
            id="email"
            name="email"
            value={email}
            type="text"
            onChange={onEmailChange}
          />
        </div>
        <Button
          className="changepasswordreq_container_button"
          onClick={onSubmit}
          disabled={!validEmail}
        >
          {inProcess === true ? 'Requesting' : 'Request'}
        </Button>
        <div className="changepasswordreq_container_success_link ">
          <Link to="/">
            <ArrowBackIosIcon /> Go to login
          </Link>
        </div>
        <div className="changepasswordreq_container_logos">
          <Logos />
        </div>
      </>
    );
  };

  const errorView = () => {
    return (
      <>
        <div className="changepasswordreq_container_error">
          <div className="changepasswordreq_container_error_text">
            There was an unexpected problem with the system.
          </div>
        </div>
        <Button
          className="changepasswordreq_container_button"
          onClick={onResetRequest}
        >
          Retry?
        </Button>
      </>
    );
  };

  const viewToShow = () => {
    if (requested && !successRequest) {
      return errorView();
    }

    return requestView();
  };

  return (
    <div className="changepassword">
      <Paper className="changepasswordreq_container">
        <div className={'changepasswordreq_container_title'}>
          Change password request
        </div>
        {viewToShow()}
      </Paper>
    </div>
  );
};

export default ChangePasswordRequestView;
