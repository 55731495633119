/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import './ObserverModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import MUITextField from '../../../components/inputs/MUITextField';

const ObserverModalView = ({ data, onInputChange, onClose, onSave }) => {
  return (
    <ModalComponent
      open={true}
      title={data?.id ? 'Edit observer' : 'Add observer'}
      onClose={onClose}
      onConfirm={onSave}
    >
      <div className="observermodal">
        <MUITextField
          label="First Name"
          name="first_name"
          value={data?.first_name}
          type="text"
          onChange={onInputChange}
        />
        <MUITextField
          label="Last name"
          name="last_name"
          value={data?.last_name}
          type="text"
          onChange={onInputChange}
        />
        <MUITextField
          label="Grade"
          name="grade"
          value={data?.grade}
          type="number"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default ObserverModalView;
