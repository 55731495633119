/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import { TextField } from '@material-ui/core';

/** Styles **/
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
    // height: '65px',
    // minHeight: '65px'
  },
  '&:hover': {
    backgroundColor: theme.palette.normal.main
  },
  focused: { backgroundColor: '#fff' }
}));

const styles = () => ({
  inputLabel: {
    fontSize: '12px',
    height: '12px',
    paddingLeft: '10px'
  },
  input: {
    margin: 0,
    verticalAlign: 'center',
    backgroundColor: 'white',
    // height: '35px',
    placeholderTextColor: '#ced4da',
    // padding: '10px',
    fontSize: '13px'
  }
});

export const MUITextField = ({
  name,
  id,
  labelId,
  label,
  type = 'text',
  value,
  onChange,
  style,
  disabled,
  placeholder,
  color = 'secondary',
  styleText,
  disabledText,
  ...props
}) => {
  const classes = useStyles();
  const customClasses = styles();

  const handleOnChange = (e) => {
    const inputValue = e.target.value;

    const returnValue = inputValue
      ? type === 'number'
        ? parseFloat(inputValue)
        : inputValue
      : null;

    onChange({ id: id, name: name, value: returnValue });
  };

  return (
    <div id={'MUI_' + id} className={classes.root}>
      {label && (
        <InputLabel
          id={labelId ? labelId : label + '__InputLabel'}
          style={customClasses.inputLabel}
        >
          {label}
        </InputLabel>
      )}

      <TextField
        autoComplete="new"
        id={id}
        type={type}
        name={name}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onChange={handleOnChange}
        style={customClasses.input}
        {...props}
      />
    </div>
  );
};

export default MUITextField;
