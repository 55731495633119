/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import services section **/
import {
  addSchoolService,
  updateSchoolService
} from '../../../api/services/schools-services';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/

/** Import component section **/
import SchoolModalView from './SchoolModalView';

const SchoolModalComponent = ({ item, states, onClose, onUpdateList }) => {
  const [itemData, setItemData] = useState({});

  useEffect(() => {
    setItemData(item);
  }, [item]);

  const handleOnInputChange = (data) => {
    const { value, name } = data;
    setItemData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnSave = (event) => {
    event.preventDefault();
    if (itemData.id) {
      updateSchoolService(itemData)
        .then(() => {
          toast.success('School updated');
          onUpdateList();
        })
        .catch((err) => {
          if (err?.response_status) toast.error("Error, can't update");
        });
    } else {
      addSchoolService(itemData)
        .then(() => {
          toast.success('School added');
          onUpdateList();
        })
        .catch((err) => {
          if (err?.response_status) toast.error("Error, can't save");
        });
    }
  };

  return (
    <SchoolModalView
      data={itemData}
      states={states}
      onInputChange={handleOnInputChange}
      onSave={handleOnSave}
      onClose={onClose}
    />
  );
};

export default SchoolModalComponent;
