/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import { Paper } from '@material-ui/core';

/** Import styles section **/
import './EmailValidationStyles.scss';

/** Import helpers section **/

/** Import component section **/
import Logos from '../../components/Logos';

const EmailValidationView = ({ finished, confirmed }) => {
  const initialView = () => {
    return (
      <>
        <span className="emailvalidation_container_title">
          Verifing email...
        </span>
      </>
    );
  };

  const errorView = () => {
    return (
      <>
        <span className="emailvalidation_container_title error">
          Error in verification.
        </span>
      </>
    );
  };

  const viewToShow = () => {
    if (finished && !confirmed) {
      return errorView();
    }

    return initialView();
  };

  return (
    <div className="emailvalidation">
      <Paper className="emailvalidation_container">
        {viewToShow()}
        <div className="emailvalidation_container_logos">
          <Logos />
        </div>
      </Paper>
    </div>
  );
};

export default EmailValidationView;
