/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';

/** Import styles section **/
import './SchoolsStyles.scss';

/** Import helpers section **/

/** Import component section **/
import AddButton from '../../components/AddButton';
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';
import ActionsCell from '../../components/ActionsCell';

const SchoolsView = ({ data, inProcess, onAdd, onEdit, onDelete }) => {
  const mixedAddress = (row) => {
    let resultAddress = '';
    if (row.address1) resultAddress = row.address1;
    if (row.address2) resultAddress = resultAddress + ' ' + row.address2;
    return resultAddress;
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'id',
        selector: 'id',
        omit: true
      },
      {
        name: 'School',
        selector: 'name',
        sortable: true,
        center: true
      },
      {
        name: 'RFC',
        selector: 'rfc',
        sortable: true,
        center: true
      },
      {
        name: 'Phone',
        selector: 'phone',
        sortable: true,
        center: true
      },
      {
        name: 'Address',
        selector: (row) => mixedAddress(row),
        cell: (row) => <>{mixedAddress(row)}</>,
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => (
          <ActionsCell item={row} onEdit={onEdit} onDelete={onDelete} />
        ),
        ignoreRowClick: true,
        width: '100px'
      }
    ],
    [onEdit, onDelete]
  );

  return (
    <>
      <PageTitle title="Schools" />
      <Paper className="g-page-header schools_header">
        <AddButton onClick={onAdd} label=" Add School" />
      </Paper>
      <DataTable data={data} columns={tableColumns} loading={inProcess} />
    </>
  );
};

export default SchoolsView;
