/** Import react/libraries section **/
import React from 'react';
import { Link } from 'react-router-dom';

/** Import resources section **/
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

/** Import styles section **/
import './HeaderStyles.scss';

/** Import helpers section **/

/** Import component section **/

export const HeaderView = ({ items, userName, schoolName, onLogout }) => {
  return (
    <div className="mainheader">
      {/*<div className="mainheader_title_logo">
        <img src={logo} alt='' className='mainheader_title_logo_icon'/>
      </div>*/}
      <div className="mainheader_menu">
        {items?.map((item, index) => (
          <div key={index} className="mainheader_menu_item">
            <Link className="mainheader_menu_item_link" to={item.link}>
              {item.title}
            </Link>
          </div>
        ))}
      </div>
      <div className="mainheader_session">
        <PersonIcon className="mainheader_session_usericon" />
        <div className="mainheader_session_profile">
          <div className="mainheader_session_profile_user">{userName}</div>
          {schoolName && schoolName.length && (
            <div className="mainheader_session_profile_school">
              {schoolName}
            </div>
          )}
        </div>
        <div className="mainheader_session_logout">
          <ExitToAppIcon
            className="mainheader_session_logout_icon"
            onClick={onLogout}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderView;
