/** Import react/libraries section **/
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

/** Import helpers section **/
import { isValidEmail } from '../../utils/validations.js';
import { LOGIN_ROUTE } from '../../URL-routes';

/** Import services section **/
import { changePasswordService } from '../../api/services/change-password-services';

/** Import resources section **/
import './ChangePasswordStyles.scss';

/** Import component section **/
import ChangePasswordView from './ChangePasswordView';
import ConfirmationModal from '../../components/ConfirmationModal';

const ChangePasswordComponent = () => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordConf, setPasswordConf] = useState(null);
  const [validForm, setValidForm] = useState(false);

  const [inProcess, setInProcess] = useState(false);
  const [requested, setRequested] = useState(false);
  const [successRequest, setSuccessRequest] = useState(false);

  let history = useHistory();

  let pageParams = useParams();
  let token = pageParams.token;

  const handleOnSubmit = (data) => {
    setInProcess(true);
    changePasswordService({ email, token, password })
      .then((response) => {
        setSuccessRequest(true);
      })
      .catch((err) => {
        setSuccessRequest(false);
      })
      .finally(() => {
        setRequested(true);
        setInProcess(false);
      });
  };

  const handeOnEmailChange = (data) => {
    setEmail(data.value);
  };

  const handeOnPasswordChange = (data) => {
    setPassword(data?.value);
  };

  const handeOnPasswordConfChange = (data) => {
    setPasswordConf(data?.value);
  };

  useEffect(() => {
    let validEmail = isValidEmail(email);
    let validPasswords =
      password && passwordConf
        ? password?.trim() === passwordConf?.trim()
        : false;
    setValidForm(validEmail && validPasswords);
  }, [password, passwordConf, email]);

  const handleOnConfirmModalClose = () => {
    history.push(LOGIN_ROUTE);
  };

  return (
    <>
      <ChangePasswordView
        inProcess={inProcess}
        email={email}
        password={password}
        passwordConf={passwordConf}
        onEmailChange={handeOnEmailChange}
        onPasswordChange={handeOnPasswordChange}
        onPasswordConfChange={handeOnPasswordConfChange}
        validForm={validForm}
        onSubmit={handleOnSubmit}
        requested={requested}
        successRequest={successRequest}
      />
      {successRequest && (
        <ConfirmationModal
          title={'Password successfully changed!'}
          message={'Go to login again.'}
          onClose={handleOnConfirmModalClose}
        />
      )}
    </>
  );
};

export default ChangePasswordComponent;
