/** Import react/libraries section **/
import React from 'react';

/** Import styles section **/
import './SchoolRegistryStyles.scss';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import MUITextField from '../../components/inputs/MUITextField';
import MUISelect from '../../components/inputs/MUISelect';
import Loading from '../../components/Loading';

const SchoolRegistryView = ({
  inProcess,
  loadFinished,
  data,
  assistanceConfirmed,
  states,
  onInputChange,
  onSave
}) => {
  return (
    <>
      <PageTitle title="School information" />
      {inProcess && <Loading />}
      {loadFinished && (
        <Paper className="schoolregistry">
          <div className="schoolregistry_form">
            <MUITextField
              label="School"
              name="name"
              value={data?.name}
              type="text"
              onChange={onInputChange}
              disabled={true}
            />
            <MUITextField
              label="Official Name SAT"
              name="sat_name"
              value={data?.sat_name}
              type="text"
              onChange={onInputChange}
            />
            <MUITextField
              label="RFC"
              name="rfc"
              value={data?.rfc}
              type="text"
              onChange={onInputChange}
            />

            <MUITextField
              label="Address 1"
              name="address1"
              value={data?.address1}
              type="text"
              onChange={onInputChange}
            />
            <MUITextField
              label="Address 2"
              name="address2"
              value={data?.address2}
              type="text"
              onChange={onInputChange}
            />
            <MUITextField
              label="Zip"
              name="zip"
              value={data?.zip}
              type="text"
              onChange={onInputChange}
            />

            <MUISelect
              label="State"
              name="states_id"
              id="states_id"
              defaultValue={data?.states_id}
              items={states}
              idSelector="id"
              selector="name"
              noSelectedLabel="Select"
              onChange={onInputChange}
            />
            <MUITextField
              label="City"
              name="city"
              value={data?.city}
              type="text"
              onChange={onInputChange}
            />
            <MUITextField
              label="Phone"
              name="phone"
              value={data?.phone}
              type="text"
              onChange={onInputChange}
            />
            <MUITextField
              label="# of Middle School Delegates"
              name="ms_quantity"
              value={data?.ms_quantity}
              type="number"
              onChange={onInputChange}
            />
            <MUITextField
              label="# of High School Delegates"
              name="hs_quantity"
              value={data?.hs_quantity}
              type="number"
              onChange={onInputChange}
            />
          </div>
          <div className="schoolregistry_buttons">
            <Button
              onClick={onSave}
              type="submit"
              disabled={assistanceConfirmed}
            >
              SAVE
            </Button>
          </div>
        </Paper>
      )}
    </>
  );
};

export default SchoolRegistryView;
