// System routes.

export const HOME_ROUTE = '/';

export const LOGIN_ROUTE = '/login';
export const REGISTRATION_ROUTE = '/registration';
export const EMAIL_VALIDATION_ROUTE = '/register-verify';
export const CHANGE_PASSWORD_REQUEST_ROUTE = '/change-password-request';
export const CHANGE_PASSWORD_ROUTE = '/change-password';

export const SCHOOLS_ROUTE = '/schools';
export const EVENTS_ROUTE = '/events';
export const EVENT_REGISTRATIONS_ROUTE = '/event-registrations';
export const EVENT_DASHBOARD_ROUTE = '/event-dashboard';
export const EVENT_CONFIG_ROUTE = '/event-configuration';

export const REGISTRY_ROUTE = '/registry';
export const ADVISORS_ROUTE = '/advisors';
export const OBSERVERS_ROUTE = '/observers';
export const PAYMENT_ROUTE = '/payment';
export const ASSIGN_ROUTE = '/assign';
