/** Import react/libraries section **/
import React from 'react';

/** Import styles section **/
import './SchoolAssignModalStyles.scss';

/** Import resources section **/

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import MUISelect from '../../../components/inputs/MUISelect';

const SchoolAssignModalView = ({
  schools = [],
  organismsName,
  selectedSchool,
  selectByCountry,
  onSchoolChange,
  onClose,
  onSave
}) => {
  return (
    <ModalComponent
      open={true}
      title={'Assign School to ' + organismsName + ' organization'}
      onClose={onClose}
      onConfirm={onSave}
    >
      <MUISelect
        label="School"
        name="schools"
        id="schools"
        items={schools}
        idSelector="id"
        selector="school_name"
        defaultValue={schools.length > 0 ? selectedSchool : null}
        noSelectedLabel="Select"
        onChange={onSchoolChange}
      />
    </ModalComponent>
  );
};

export default SchoolAssignModalView;
