/** Import react/libraries section **/
import React, { useState, useEffect, useCallback } from 'react';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/

/** Import services section **/
import {
	getDashboardService,
	validatePaymentStatusService,
	confirmAssistanceDashboadService
} from '../../api/services/dashboard-services';

/** Import component section **/
import DashboardView from './DashboardView';
import ModalComponent from '../../components/Modal';

function DashboardComponent() {
	const [inProcess, setInProcess] = useState(false);
	const [dashboardData, setDashboardData] = useState([]);
	const [selectedItem, setSelectedItem] = useState(null);
	const [modalMode, setModalMode] = useState('');

	const getDashboard = useCallback(() => {
		setInProcess(true);
		getDashboardService()
			.then((response) => {
				setDashboardData(response);
			})
			.catch((err) => {
				if (err?.response_status) toast.error("Can't load information");
				console.error('Error getting dashboard: ', err);
			})
			.finally(() => {
				setInProcess(false);
			});
	}, []);

	useEffect(() => {
		getDashboard();
	}, [getDashboard]);

	const handleOnModalClose = () => {
		clearStates();
	};

	const handleOnValidateStatus = (row, status) => {
		setSelectedItem(row);
		setModalMode(status);
	};
	const handleOnOpneAssistance = (row, status) => {
		setSelectedItem(row);
		setModalMode(status);
	};

	const handleOnValidateConfirm = () => {
		let params = {
			id: selectedItem.id
		};
		validatePaymentStatusService(params)
			.then((response) => {
				toast.success('Payment status changed');
			})
			.catch((err) => {
				if (err?.response_status)
					toast.error("Error, can't change status");
				console.error('Error changing status: ', err);
			})
			.finally(() => {
				clearStates();
				getDashboard();
			});
	};
	const handleOnConfirmAssistance = () => {
		let params = {
			id: selectedItem.id
		};
		confirmAssistanceDashboadService(params)
			.then((response) => {
				toast.success('Assistance confirmed');
			})
			.catch((err) => {
				if (err?.response_status)
					toast.error("Error, can't change status");
				console.error('Error changing status: ', err);
			})
			.finally(() => {
				clearStates();
				getDashboard();
			});
	};

	const clearStates = () => {
		setSelectedItem(null);
		setModalMode('');
	};

	return (
		<>
			<DashboardView
				inProcess={inProcess}
				data={dashboardData}
				onValidateStatus={handleOnValidateStatus}
				onConfirmAssistance={handleOnOpneAssistance}
			/>
			{selectedItem && modalMode === 'payment' && (
				<ModalComponent
					open={true}
					onClose={handleOnModalClose}
					onConfirm={handleOnValidateConfirm}
					title="Validate registration"
				>
					{'Are you sure to ' +
						(selectedItem[modalMode + '_done']
							? 'invalidate '
							: 'validate ') +
						modalMode +
						'?'}
				</ModalComponent>
			)}
			{selectedItem && modalMode === 'assistance' && (
				<ModalComponent
					open={true}
					onClose={handleOnModalClose}
					onConfirm={handleOnConfirmAssistance}
					title="Confirm assistance"
				>
					{`Are you sure to confirm
						${selectedItem.school.name}
						${modalMode}?`}
				</ModalComponent>
			)}
		</>
	);
}

export default DashboardComponent;
