/** Import react/libraries section **/
import React, { useState, useEffect, useCallback } from 'react';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/

/** Import services section **/
import {
  getAssignService,
  removeAssignService,
  confirmAssignService
} from '../../api/services/assign-services';
import { getSchoolReportService } from '../../api/services/reports-services';

/** Import component section **/
import AssignView from './AssignView';
import AssignModal from './AssignModal';
import ModalComponent from '../../components/Modal';

function AssignComponent() {
  const [inProcess, setInProcess] = useState(false);
  const [delegates, setDelegates] = useState([]);
  const [confirmationCheck, setConfirmationCheck] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [modalMode, setModalMode] = useState('');

  const getAssign = useCallback(() => {
    setInProcess(true);
    getAssignService()
      .then((response) => {
        const confirmed =
          response?.confirm_finish_school_assigment === 1 ? true : false;
        setConfirmationCheck(confirmed);
        setDelegates(response?.organisms);
      })
      .catch((err) => {
        const status = err.response_status;

        let message = '';
        switch (status) {
          case 422:
            message =
              'Your school is not ready to assign, please contact U&P administrator.';
            break;
          default:
            message = 'Unexpected error';
            break;
        }
        if (status) toast.error(message);
        console.error('Error login: ', err);
      })
      .finally(() => {
        setInProcess(false);
      });
  }, []);

  useEffect(() => {
    getAssign();
  }, [getAssign]);

  const handleOnDownloadReport = () => {
    getSchoolReportService()
      .then((response) => {
        window.open(response?.url, '_blank');
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Error, can't get report");
        console.error('Error getting report: ', err);
      })
      .finally(() => {});
  };

  const handleOnEdit = (row) => {
    const assignitem = {
      level: row?.committee?.level,
      organism_id: row.id,
      organism_name: row.name,
      id: row?.assigned?.id,
      first_name: row?.assigned?.first_name,
      last_name: row?.assigned?.last_name,
      grade: row?.assigned?.grade
    };
    setSelectedItem(assignitem);
    setModalMode('assign');
  };

  const handleOnDelete = (row) => {
    setSelectedItem(row);
    setModalMode('delete');
  };

  const handleOnModalClose = () => {
    clearStates();
  };

  const handleOnDeleteConfirm = () => {
    removeAssignService(selectedItem)
      .then((response) => {
        toast.success('Assign deleted');
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Error, can't delete advisor");
        console.error('Error deleting advisor: ', err);
      })
      .finally(() => {
        clearStates();
        getAssign();
      });
  };

  const handleOnConfirmationChange = () => {
    setOpenConfirmationModal(true);
  };

  const handleOnConfirmationModalClose = () => {
    setOpenConfirmationModal(false);
  };

  const handleOnConfirmationConfirm = () => {
    confirmAssignService()
      .then((response) => {
        setConfirmationCheck(true);
        toast.success('Confirmation successly changed');
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't change confirmation");
        console.error('Error getting confirm data: ', err);
      })
      .finally(() => {
        setOpenConfirmationModal(false);
      });
  };

  const handleOnUpdateList = (data) => {
    clearStates();
    getAssign();
  };

  const clearStates = () => {
    setSelectedItem(null);
    setModalMode('');
  };

  return (
    <>
      <AssignView
        inProcess={inProcess}
        data={delegates}
        confirmationCheck={confirmationCheck}
        onDownloadReport={handleOnDownloadReport}
        onConfirmationChange={handleOnConfirmationChange}
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
      />
      {selectedItem && modalMode === 'assign' && (
        <AssignModal
          item={selectedItem}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedItem && modalMode === 'delete' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Delete delegate"
        >
          {'Are you sure to unassign ' +
            selectedItem?.assigned?.first_name +
            ' ' +
            selectedItem?.assigned?.last_name +
            ' from ' +
            selectedItem?.name +
            '?'}
        </ModalComponent>
      )}
      {openConfirmationModal && (
        <ModalComponent
          open={true}
          onClose={handleOnConfirmationModalClose}
          onConfirm={handleOnConfirmationConfirm}
          title="Delegates assign confirm"
        >
          <>
            <div>Are you sure to confirm delegate assignation?.</div>
            <div className="events_alerttext">This action can't be undone.</div>
          </>
        </ModalComponent>
      )}
    </>
  );
}

export default AssignComponent;
