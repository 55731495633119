import request from '../api-connector';

export const getObserversService = () => {
  const serviceDef = {
    url: 'observers/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const addObserverService = ({ first_name, last_name, grade }) => {
  const serviceDef = {
    url: 'observers/create',
    method: 'post',
    params: { first_name, last_name, grade }
  };
  return request(serviceDef);
};

export const updateObserverService = ({ id, first_name, last_name, grade }) => {
  const serviceDef = {
    url: 'observers/update',
    method: 'post',
    params: { id, first_name, last_name, grade }
  };
  return request(serviceDef);
};

export const deleteObserverService = ({ id }) => {
  const serviceDef = {
    url: 'observers/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};
