/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

/** Import services section **/
import {
  addCommitteeService,
  updateCommitteeService
} from '../../../api/services/event-configuration-services';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/
import { setUpdate } from '../../../store/slices/refresh-slice';

/** Import component section **/
import CommitteeModalView from './CommitteeModalView';

const CommitteeModalComponent = ({ item, onClose }) => {
  const [itemData, setItemData] = useState({});
  let dispatch = useDispatch();

  useEffect(() => {
    setItemData(item);
  }, [item]);

  const handleOnInputChange = (data) => {
    const { value, name } = data;
    setItemData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnSave = (event) => {
    event.preventDefault();
    if (itemData.id) {
      updateCommitteeService(itemData)
        .then((response) => {
          toast.success('Committee updated');
          onRefreshData();
        })
        .catch((err) => {
          if (err?.response_status)
            toast.error("Error, can't update committee");
        });
    } else {
      addCommitteeService(itemData)
        .then(() => {
          toast.success('Committee added');
          onRefreshData();
        })
        .catch((err) => {
          if (err?.response_status) toast.error("Error, can't save committee");
        });
    }
  };

  const onRefreshData = () => {
    dispatch(setUpdate({ update: true }));
  };

  return (
    <CommitteeModalView
      data={itemData}
      onInputChange={handleOnInputChange}
      onSave={handleOnSave}
      onClose={onClose}
    />
  );
};

export default CommitteeModalComponent;
