/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import services section **/
import {
  addEventService,
  updateEventService
} from '../../../api/services/events-services';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/

/** Import component section **/
import EventModalView from './EventModalView';

const EventModalComponent = ({ item, onClose, onUpdateList }) => {
  const [itemData, setItemData] = useState({});

  useEffect(() => {
    setItemData(item);
  }, [item]);

  const handleOnInputChange = (data) => {
    if (!data) return;
    const { value, name } = data;
    setItemData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnSave = (event) => {
    event.preventDefault();
    if (itemData.id) {
      updateEventService(itemData)
        .then((response) => {
          toast.success('Event updated');
          onUpdateList();
        })
        .catch((err) => {
          if (err?.response_status) toast.error("Error, can't update event");
        });
    } else {
      addEventService(itemData)
        .then(() => {
          toast.success('Event added');
          onUpdateList();
        })
        .catch((err) => {
          if (err?.response_status) toast.error("Error, can't save event");
        });
    }
  };

  return (
    <EventModalView
      data={itemData}
      onInputChange={handleOnInputChange}
      onSave={handleOnSave}
      onClose={onClose}
    />
  );
};

export default EventModalComponent;
