/** Import react/libraries section **/
import React, { useState, useEffect, useCallback } from 'react';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/

/** Import services section **/
import {
  getSchoolsService,
  deleteSchoolService
} from '../../api/services/schools-services';

import { getStatesService } from '../../api/services/catalogues-services';

/** Import component section **/
import SchoolsView from './SchoolsView';
import SchoolModal from './SchoolModal';
import ModalComponent from '../../components/Modal';

const SchoolModel = {
  id: null,
  name: null,
  rfc: null,
  sat_name: null,
  phone: null,
  address1: null,
  address2: null,
  city: null,
  states_id: null,
  zip: null
};

function SchoolsComponent() {
  const [inProcess, setInProcess] = useState(false);
  const [schools, setSchools] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalMode, setModalMode] = useState('');

  const getSchools = useCallback(() => {
    setInProcess(true);
    getSchoolsService()
      .then((response) => {
        setSchools(response);
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't load information");
        console.error('Error getting schools: ', err);
      })
      .finally(() => {
        setInProcess(false);
      });
  }, []);

  const getStates = useCallback(() => {
    setInProcess(true);
    getStatesService()
      .then((response) => {
        setStates(response);
        getSchools();
      })
      .catch((err) => {
        setInProcess(false);
        if (err?.response_status) toast.error("Can't load information");
        console.error('Error getting schools: ', err);
      });
  }, [getSchools]);

  useEffect(() => {
    getStates();
  }, [getStates]);

  const handleOnAdd = () => {
    const newItem = JSON.parse(JSON.stringify(SchoolModel));
    setSelectedItem(newItem);
    setModalMode('add');
  };

  const handleOnEdit = (row) => {
    setSelectedItem(row);
    setModalMode('edit');
  };

  const handleOnDelete = (row) => {
    setSelectedItem(row);
    setModalMode('delete');
  };

  const handleOnModalClose = () => {
    clearStates();
  };

  const handleOnDeleteConfirm = () => {
    deleteSchoolService(selectedItem)
      .then((response) => {
        toast.success('School deleted');
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Error, can't delete school");
        console.error('Error deleting school: ', err);
      })
      .finally(() => {
        clearStates();
        getSchools();
      });
  };

  const handleOnUpdateList = (data) => {
    clearStates();
    getSchools();
  };

  const clearStates = () => {
    setSelectedItem(null);
    setModalMode('');
  };

  return (
    <>
      <SchoolsView
        inProcess={inProcess}
        data={schools}
        onAdd={handleOnAdd}
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
      />
      {selectedItem && (modalMode === 'add' || modalMode === 'edit') && (
        <SchoolModal
          item={selectedItem}
          states={states}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedItem && modalMode === 'delete' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Delete school"
        >
          {'Are you sure to delete ' + selectedItem.name}
        </ModalComponent>
      )}
    </>
  );
}

export default SchoolsComponent;
