/** Import react/libraries section **/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

/** Import component section **/

import SchoolsComponent from '../../views/Schools';
import EventsComponent from '../../views/Events';
import RegistrationRecordsComponent from '../../views/RegistrationRecords';
import DashboardComponent from '../../views/Dashboard';
import EventConfigurationComponent from '../../views/EventConfiguration';

import SchoolRegistryComponent from '../../views/SchoolRegistry';
import AdvisorsComponent from '../../views/Advisors';
import ObserversComponent from '../../views/Observers';
import PaymentComponent from '../../views/Payment';
import AssignComponent from '../../views/Assign';

/** Import helpers section **/
import * as routes from '../../URL-routes';

/** Import resources section **/

const ContentSwitch = ({ role }) => {
  if (role === 1) {
    return (
      <Switch>
        <Route exact path="/">
          <Redirect to={routes.SCHOOLS_ROUTE} />
        </Route>
        <Route exact path={routes.SCHOOLS_ROUTE} component={SchoolsComponent} />
        <Route exact path={routes.EVENTS_ROUTE} component={EventsComponent} />
        <Route
          exact
          path={routes.EVENT_REGISTRATIONS_ROUTE}
          component={RegistrationRecordsComponent}
        />
        <Route
          exact
          path={routes.EVENT_DASHBOARD_ROUTE}
          component={DashboardComponent}
        />
        <Route
          exact
          path={routes.EVENT_CONFIG_ROUTE}
          component={EventConfigurationComponent}
        />
      </Switch>
    );
  }

  if (role === 2) {
    return (
      <Switch>
        <Route exact path="/">
          <Redirect to={routes.REGISTRY_ROUTE} />
        </Route>
        <Route
          exact
          path={routes.REGISTRY_ROUTE}
          component={SchoolRegistryComponent}
        />
        <Route
          exact
          path={routes.ADVISORS_ROUTE}
          component={AdvisorsComponent}
        />
        <Route
          exact
          path={routes.OBSERVERS_ROUTE}
          component={ObserversComponent}
        />
        <Route exact path={routes.PAYMENT_ROUTE} component={PaymentComponent} />
        <Route exact path={routes.ASSIGN_ROUTE} component={AssignComponent} />
      </Switch>
    );
  }

  return <></>;
};

export default ContentSwitch;
