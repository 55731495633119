/** Import react/libraries section **/
import React from 'react';

/** Import styles section **/
import './LogosStyles.scss';

/** Import resources section **/
import JFKLogo from '../../assets/logoJFK.png';
import UPLogo from '../../assets/logoUP.png';

export const LogosComponent = () => {
  return (
    <div className="logos">
      <div className="logos_union">
        <img src={UPLogo} alt="up-logo" className="logos_union_img" />
      </div>
      <div className="logos_jfk">
        <img src={JFKLogo} alt="jfk-logo" className="logos_jfk_img" />
      </div>
    </div>
  );
};

export default LogosComponent;
