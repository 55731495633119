/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

/** Import resources section **/
import { emailValidationService } from '../../api/services/registration-services';

/** Import styles section **/

/** Import helpers section **/
import { LOGIN_ROUTE } from '../../URL-routes';

/** Import component section **/
import EmailValidationView from './EmailValidationView';
import ConfirmationModal from '../../components/ConfirmationModal';

const EmailValidationComponent = () => {
  const [finished, setFinished] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [message, setMessage] = useState('');

  const history = useHistory();
  let pageParams = useParams();
  let token = pageParams.token;

  const getValidation = useCallback(() => {
    emailValidationService({ token })
      .then((response) => {
        setConfirmed(true);
      })
      .catch((err) => {
        const status = err.response_status;
        if (status === 400) {
          setMessage('This token not longer valid');
        }
        console.error('Error getting validation: ', err);
      })
      .finally(() => {
        setFinished(true);
      });
  }, [token]);

  useEffect(() => {
    getValidation();
  }, [getValidation]);

  const handleOnConfirmModalClose = () => {
    history.push(LOGIN_ROUTE);
  };
  return (
    <>
      <EmailValidationView
        finished={finished}
        confirmed={confirmed}
        message={message}
      />

      {confirmed && (
        <ConfirmationModal
          title={'Email validated successfully!'}
          message={
            'Your email was verified. In order to activate your account, please wait until the U&P administrator authorizes your account. You will receive an email with your authorization in 24-48 hours.'
          }
          onClose={handleOnConfirmModalClose}
        />
      )}
    </>
  );
};

export default EmailValidationComponent;
