/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import helpers section **/
import { formatNumberToCurrency } from '../../utils/formats';

/** Import styles section **/
import './EventsStyles.scss';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import AddButton from '../../components/AddButton';
import DataTable from '../../components/DataTable';
import ActiveCell from '../../components/ActiveCell';
import ActionsCell from '../../components/ActionsCell';

const EventsView = ({ inProcess, data, onAdd, onActive, onEdit, onDelete }) => {
  const dateYear = (dateStr) => {
    return dateStr.split('-')[0];
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'id',
        selector: 'id',
        omit: true
      },
      {
        name: 'Year',
        selector: (row) => dateYear(row?.date),
        sortable: true,
        center: true
      },
      {
        name: 'Title',
        selector: 'name',
        sortable: true,
        center: true
      },
      {
        name: 'Start date',
        selector: 'date',
        sortable: true,
        center: true
      },
      {
        name: 'Event price',
        selector: 'event_price',
        format: (row) => formatNumberToCurrency(row?.event_price),
        sortable: true,
        center: true
      },
      {
        name: 'Total schools',
        selector: 'total_schools',
        sortable: true,
        center: true
      },
      {
        name: 'Active',
        selector: 'active',
        cell: (row) => (
          <ActiveCell
            active={row.active ? true : false}
            onSwitchActive={() => onActive(row)}
          />
        ),
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => (
          <ActionsCell item={row} onEdit={onEdit} onDelete={onDelete} />
        ),
        ignoreRowClick: true,
        width: '100px'
      }
    ],
    [onActive, onEdit, onDelete]
  );

  return (
    <>
      <PageTitle title="Events" />
      <Paper className="g-page-header events_header">
        <AddButton onClick={onAdd} label=" Add Event" />
      </Paper>
      <DataTable data={data} columns={tableColumns} loading={inProcess} />
    </>
  );
};

export default EventsView;
