/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';

/** Import resources section **/
import { formatNumberToCurrency } from '../../utils/formats';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';

/** Import styles section **/
import './PaymentStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';

const PaymentView = ({
  data,
  confirmationCheck,
  onConfirmationChange,
  onLoad
}) => {
  return (
    <>
      <PageTitle title="Confirmation" />
      <Paper className="payment">
        <div className="payment_confirm">
          <Switch
            checked={confirmationCheck}
            onChange={onConfirmationChange}
            name="active"
            color="secondary"
            disabled={confirmationCheck}
          />
          <div>I confirm my participation in this event </div>
        </div>
      </Paper>
      <div className="payment-space" />
      {data && (
        <>
          <PageTitle title="Payment detail" />
          <Paper className="payment">
            <div className="payment_detail">
              <div className="payment_detail-title payment_detail-header">
                Item
              </div>
              <div className="payment_detail-header">Quantity</div>
              <div className="payment_detail-header">Cost</div>
              <div className="payment_detail-header">Price</div>
              <div className="payment_detail-title">Middle School Students</div>
              <div>{data?.ms_quantity}</div>
              <div>
                {formatNumberToCurrency(data?.ms_price / data?.ms_quantity, 2)}
              </div>
              <div>{formatNumberToCurrency(data?.ms_price, 2)}</div>
              <div className="payment_detail-title">High School Students</div>
              <div>{data?.hs_quantity}</div>
              <div>
                {formatNumberToCurrency(data?.hs_price / data?.hs_quantity, 2)}
              </div>
              <div>{formatNumberToCurrency(data?.hs_price, 2)}</div>
              <div className="payment_detail-title">Advisors</div>
              <div>{data?.advisors_quantity}</div>
              <div>
                {formatNumberToCurrency(
                  data?.advisors_price / data?.advisors_quantity,
                  2
                )}
              </div>
              <div>{formatNumberToCurrency(data?.advisors_price, 2)}</div>
              <div className="payment_detail-title">Observers</div>
              <div>{data?.observers_quantity}</div>
              <div>
                {formatNumberToCurrency(
                  data?.observers_price / data?.observers_quantity,
                  2
                )}
              </div>
              <div>{formatNumberToCurrency(data?.observers_price, 2)}</div>
              <div className="payment_detail-title">Total</div>
              <div></div>
              <div></div>
              <div>{formatNumberToCurrency(data?.total_amount, 2)}</div>
            </div>
            <div className="payment_buttons">
              <a
                href={'/bank_deposit_details.pdf'}
                target="_blank"
                rel="noreferrer"
                className="payment_buttons_link"
              >
                <Button className="g-button" startIcon={<GetAppIcon />}>
                  Download bank deposit details PDF
                </Button>
              </a>

              <Button
                className="g-button check"
                startIcon={<PublishIcon />}
                onClick={onLoad}
                type="submit"
              >
                {(data?.receipt_image ? 'Reload' : 'Load') + ' payment image'}
              </Button>
              {data?.payment_file_address && (
                <a
                  href={data?.payment_file_address}
                  target="_blank"
                  rel="noreferrer"
                  className="payment_buttons_link"
                >
                  <Button
                    className="g-button complementary "
                    startIcon={<GetAppIcon />}
                    type="submit"
                  >
                    Download image
                  </Button>
                </a>
              )}
            </div>
          </Paper>
        </>
      )}
    </>
  );
};

export default PaymentView;
