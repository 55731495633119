import { combineReducers } from 'redux';
import sessionReducer from './slices/session-slice';
import countriesReducer from './slices/countries-slice';
import refreshReducer from './slices/refresh-slice';

// import requestSlice from './request/requestSlice';

export default combineReducers({
  session: sessionReducer,
  countries: countriesReducer,
  refresh: refreshReducer
});
