/** Import react/libraries section **/
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  countriesList: []
};

const countriesSlice = createSlice({
  name: 'countries',
  initialState: initialState,
  reducers: {
    setCountries: (state, { payload: { countries } }) => {
      state.countriesList = countries;
    }
  }
});
export default countriesSlice.reducer;

export const { setCountries } = countriesSlice.actions;
