import request from '../api-connector';

export const getCommitteReportService = ({ id }) => {
  const serviceDef = {
    url: 'committee/create-pdf',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const getSchoolReportService = () => {
  const serviceDef = {
    url: 'events-schools/create-detail-pdf',
    method: 'post',
    params: null
  };
  return request(serviceDef);
};

export const getEventReportService = () => {
  const serviceDef = {
    url: 'events/report',
    method: 'post',
    params: null
  };
  return request(serviceDef);
};
