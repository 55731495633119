/** Import react/libraries section **/
import React from 'react';

/** Import styles section **/
import './CommitteeStyles.scss';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

/** Import components section **/
import Organism from '../Organism';
import AddButton from '../../../components/AddButton';

export const CommitteeComponent = ({
  code,
  name,
  level,
  organisms,
  onDownloadReport,
  onDeleteCommittee,
  onEditCommittee,
  onAddOrganism
}) => {
  return (
    <Paper className="committee">
      <div className="committee_header">
        <div className="committee_header_title">
          {code} - {name}
        </div>
        <div className="committee_header_buttons">
          <Tooltip title="Committe report">
            <GetAppIcon
              className="committee_header_buttons_download"
              onClick={onDownloadReport}
            />
          </Tooltip>

          <EditIcon
            className="committee_header_buttons_edit"
            onClick={onEditCommittee}
          />
          <RemoveCircleIcon
            className="committee_header_buttons_delete"
            onClick={onDeleteCommittee}
          />
        </div>
      </div>
      <div className="committee_content">
        {organisms?.map((organismItem, index) => (
          <Organism key={index} data={organismItem} level={level} />
        ))}
      </div>
      <div className="committee_footer">
        <AddButton onClick={onAddOrganism} label=" Add organization" />
      </div>
    </Paper>
  );
};
export default CommitteeComponent;
