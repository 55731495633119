import request from '../api-connector';

export const getSchoolsService = () => {
  const serviceDef = {
    url: 'schools/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const addSchoolService = ({
  rfc,
  name,
  sat_name,
  phone,
  address1,
  address2,
  zip,
  city,
  states_id
}) => {
  const serviceDef = {
    url: 'schools/create',
    method: 'post',
    params: {
      rfc,
      name,
      sat_name,
      phone,
      address1,
      address2,
      zip,
      city,
      states_id
    }
  };
  return request(serviceDef);
};

export const updateSchoolService = ({
  id,
  rfc,
  name,
  sat_name,
  phone,
  address1,
  address2,
  zip,
  city,
  states_id
}) => {
  const serviceDef = {
    url: 'schools/update',
    method: 'post',
    params: {
      id,
      rfc,
      name,
      sat_name,
      phone,
      address1,
      address2,
      zip,
      city,
      states_id
    }
  };
  return request(serviceDef);
};

export const deleteSchoolService = ({ id }) => {
  const serviceDef = {
    url: 'schools/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};
