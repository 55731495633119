/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import helpers section **/

/** Import styles section **/
import './ObserversStyles.scss';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';

/** Import component section **/
import AddButton from '../../components/AddButton';
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';
import ActionsCell from '../../components/ActionsCell';

const ObserversView = ({
  data,
  inProcess,
  assistanceConfirmed,
  onAdd,
  onEdit,
  onDelete
}) => {
  const tableColumns = useMemo(
    () => [
      {
        name: 'id',
        selector: 'id',
        omit: true
      },
      {
        name: 'First Name',
        selector: 'first_name',
        sortable: true,
        center: true
      },
      {
        name: 'Last name',
        selector: 'last_name',
        sortable: true,
        center: true
      },
      {
        name: 'Grade',
        selector: 'grade',
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => (
          <ActionsCell
            item={row}
            onEdit={onEdit}
            onDelete={assistanceConfirmed ? null : onDelete}
          />
        ),
        ignoreRowClick: true,
        width: '100px'
      }
    ],
    [onEdit, onDelete, assistanceConfirmed]
  );

  return (
    <>
      <PageTitle title="Observers" />
      <Paper className="g-page-header observers_header">
        <AddButton
          onClick={onAdd}
          label=" Add Observer"
          disabled={assistanceConfirmed}
        />
      </Paper>
      <DataTable data={data} columns={tableColumns} loading={inProcess} />
    </>
  );
};

export default ObserversView;
