/** Import react/libraries section **/
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/

/** Import services section **/
import {
  getObserversService,
  deleteObserverService
} from '../../api/services/observers-services';

/** Import component section **/
import ObserversView from './ObserversView';
import ObserverModal from './ObserverModal';
import ModalComponent from '../../components/Modal';

const ObserverModel = {
  id: null,
  first_name: null,
  last_name: null,
  grade: null
};

function ObserversComponent() {
  const [inProcess, setInProcess] = useState(false);
  const [advisors, setObservers] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalMode, setModalMode] = useState('');

  let assistanceConfirmed = useSelector(
    (store) => store.session?.profile?.confirm_assistance
  );

  const getObservers = useCallback(() => {
    setInProcess(true);
    getObserversService()
      .then((response) => {
        setObservers(response);
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't load information");
        console.error('Error getting schools: ', err);
      })
      .finally(() => {
        setInProcess(false);
      });
  }, []);

  useEffect(() => {
    getObservers();
  }, [getObservers]);

  const handleOnAdd = () => {
    const newItem = JSON.parse(JSON.stringify(ObserverModel));
    setSelectedItem(newItem);
    setModalMode('add');
  };

  const handleOnEdit = (row) => {
    setSelectedItem(row);
    setModalMode('edit');
  };

  const handleOnDelete = (row) => {
    setSelectedItem(row);
    setModalMode('delete');
  };

  const handleOnModalClose = () => {
    clearStates();
  };

  const handleOnDeleteConfirm = () => {
    deleteObserverService(selectedItem)
      .then((response) => {
        toast.success('Observer deleted');
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Error, can't delete advisor");
        console.error('Error deleting advisor: ', err);
      })
      .finally(() => {
        clearStates();
        getObservers();
      });
  };

  const handleOnUpdateList = (data) => {
    clearStates();
    getObservers();
  };

  const clearStates = () => {
    setSelectedItem(null);
    setModalMode('');
  };

  return (
    <>
      <ObserversView
        inProcess={inProcess}
        data={advisors}
        assistanceConfirmed={assistanceConfirmed === 1 ? true : false}
        onAdd={handleOnAdd}
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
      />
      {selectedItem && (modalMode === 'add' || modalMode === 'edit') && (
        <ObserverModal
          item={selectedItem}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedItem && modalMode === 'delete' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Delete observer"
        >
          {'Are you sure to delete ' +
            selectedItem.first_name +
            ' ' +
            selectedItem.last_name +
            ' ?'}
        </ModalComponent>
      )}
    </>
  );
}

export default ObserversComponent;
