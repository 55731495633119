/** Import react/libraries section **/
import React from "react";

/** Import resources section **/
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

/** Styles **/
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  "&:hover": {
    backgroundColor: theme.palette.normal.main,
  },
  focused: { backgroundColor: "#fff" },
}));

const styles = () => ({
  inputLabel: {
    fontSize: "12px",
    height: "12px",
    paddingLeft: "10px",
  },
  input: {
    margin: 0,
    verticalAlign: "center",
    backgroundColor: "white",
    height: "35px",
    placeholderTextColor: "#ced4da",
    padding: "10px",
    fontSize: "13px",
  },
});

export const MUIAutocomplete = ({
  name,
  id,
  labelId,
  label,
  type = "text",
  value,
  onChange,
  style,
  disabled,
  placeholder,
  color = "secondary",
  styleText,
  disabledText,
  options,
  ...props
}) => {
  const classes = useStyles();
  const customClasses = styles();

  return (
    <div id={"MUI_" + id} className={classes.root}>
      {label && (
        <InputLabel
          id={labelId ? labelId : label + "__InputLabel"}
          style={customClasses.inputLabel}
        >
          {label}
        </InputLabel>
      )}

      <Autocomplete
        id={id}
        options={options}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        value={value}
        renderInput={(params) => (
          <TextField {...params} style={customClasses.input} />
        )}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

export default MUIAutocomplete;
