/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import './AssignModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import MUITextField from '../../../components/inputs/MUITextField';
import MUISelect from '../../../components/inputs/MUISelect';

const msGrades = [
  { id: 6, grade: 6 },
  { id: 7, grade: 7 },
  { id: 8, grade: 8 }
];
const hsGrades = [
  { id: 9, grade: 9 },
  { id: 10, grade: 10 },
  { id: 11, grade: 11 },
  { id: 12, grade: 12 }
];

const AssignModalView = ({ data, onInputChange, onClose, onSave }) => {
  const grades = data?.level === 1 ? msGrades : hsGrades;

  return (
    <ModalComponent
      open={true}
      title={
        'Assign delegate to ' +
        data?.organism_name +
        ' ' +
        (data?.level === 1 ? '(MS)' : '(HS)')
      }
      onClose={onClose}
      onConfirm={onSave}
    >
      <div className="assignmodal">
        <MUITextField
          label="First Name"
          name="first_name"
          value={data?.first_name}
          type="text"
          onChange={onInputChange}
        />
        <MUITextField
          label="Last name"
          name="last_name"
          value={data?.last_name}
          type="text"
          onChange={onInputChange}
        />
        <MUISelect
          label="Grade"
          name="grade"
          id="grade"
          items={grades}
          idSelector="id"
          selector="grade"
          defaultValue={data?.grade}
          noSelectedLabel="Select"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default AssignModalView;
