/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';

/** Import resources section **/
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';

/** Import styles section **/
import './EventConfigurationStyles.scss';

/** Import component section **/
import Loading from '../../components/Loading';
import PageTitle from '../../components/PageTitle';
import AddButton from '../../components/AddButton';
import Committee from './Committee';
import SchoolDistribution from './SchoolDistribution';
import EventInformation from './EventInformation';
import OrganizationDistribution from './OrganizationDistribution';

const EventConfigurationView = ({
  inProcess,
  data,
  levelCommittees,
  selectedLevel,
  onLevelChange,
  onAddCommittee,
  onEditCommittee,
  onDeleteCommittee,
  onUpdateList,
  onDownloadReport
}) => {
  const levelTabs = (
    <Tabs
      value={selectedLevel}
      onChange={onLevelChange}
      className="eventconfiguration_tabs"
    >
      <Tab label="Middle School" />
      <Tab label="High School" />
    </Tabs>
  );

  return (
    <div className="eventconfiguration">
      <PageTitle
        title={
          'Event configuration' +
          (data?.event?.name ? ' "' + data.event.name + '"' : '')
        }
        className="eventconfiguration_pagetitle"
      />
      {inProcess && <Loading />}
      {data && (
        <div className="eventconfiguration_content">
          <div className="eventconfiguration_content_config">
            <div className="eventconfiguration_content_config_controls">
              <AddButton onClick={onAddCommittee} label=" Add Committee" />
              <div className="eventconfiguration_content_config_controls_tabs">
                {levelTabs}
              </div>
            </div>
            {levelCommittees.length === 0 ? (
              <>No committes created for this level</>
            ) : (
              <>
                {levelCommittees.map((committeeItem, index) => (
                  <Committee
                    key={index}
                    data={committeeItem}
                    onEditCommittee={(event) => onEditCommittee(committeeItem)}
                  />
                ))}
              </>
            )}
          </div>
          <div className="eventconfiguration_summary">
            <PageTitle
              title="Summary"
              className="eventconfiguration_summary_title"
            >
              <Tooltip title="Event xls report">
                <GetAppIcon
                  className="eventconfiguration_summary_title_download"
                  onClick={onDownloadReport}
                />
              </Tooltip>
            </PageTitle>
            <Paper className="eventconfiguration_summary_card">
              <div className="eventconfiguration_summary_card_title">
                Event information
              </div>
              <EventInformation data={data} />
            </Paper>
            <Paper className="eventconfiguration_summary_card">
              <div className="eventconfiguration_summary_card_title">
                School distribution
              </div>
              <SchoolDistribution data={data} />
            </Paper>
            <Paper className="eventconfiguration_summary_card">
              <div className="eventconfiguration_summary_card_title">
                Organization distribution
              </div>
              <OrganizationDistribution data={data} />
            </Paper>
          </div>
        </div>
      )}
    </div>
  );
};

export const secondView = () => {};

export default EventConfigurationView;
