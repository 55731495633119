/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

/** Import services section **/
import {
  addOrganismService,
  updateOrganismService
} from '../../../api/services/event-configuration-services';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/
import { setUpdate } from '../../../store/slices/refresh-slice';

/** Import component section **/
import OrganismModalView from './OrganismModalView';

const OrganismModalComponent = ({ item, onClose }) => {
  const [itemData, setItemData] = useState({});
  const [selectByCountry, setSelectByCountry] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(null);

  let dispatch = useDispatch();
  let countries = useSelector((store) => store.countries.countriesList);

  useEffect(() => {
    setItemData(item);
  }, [item]);

  useEffect(() => {
    if (countries.length > 0) {
      let actualCountry = countries.find(
        (element) => element.name === itemData?.name
      );
      if (actualCountry) {
        setSelectByCountry(true);
        setSelectedCountry(actualCountry);
      } else {
        if (itemData?.name && itemData?.name?.trim()?.length > 0) {
          setSelectByCountry(false);
        } else {
          setSelectByCountry(true);
        }
      }
    }
  }, [countries, itemData?.name]);

  const handleOnSelectByCountryChange = () => {
    setSelectByCountry(!selectByCountry);
  };

  const handleOnInputChange = (data) => {
    const { value, name } = data;
    setItemData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnCountryChange = (data) => {
    setSelectedCountry(data);
    setItemData((prevState) => ({
      ...prevState,
      name: data?.name
    }));
  };

  const handleOnPriorityChange = (event) => {
    const newVal = itemData.priority ? 0 : 1;
    setItemData((prevState) => ({
      ...prevState,
      priority: newVal
    }));
  };

  const handleOnSave = (event) => {
    event.preventDefault();
    if (itemData.id) {
      updateOrganismService(itemData)
        .then((response) => {
          toast.success('Organization updated');
          onClose();
          onRefreshData();
        })
        .catch((err) => {
          const status = err.response_status;
          let message = '';
          switch (status) {
            case 400:
              message = "Error, can't update organization";
              break;
            case 406:
              message = "Can't create an organization with the same name within the same committee";
              break;
            default:
              message = "Unexpected error, can't update organization";
              break;
          }
          if (status) toast.error(message);
        });
    } else {
      addOrganismService(itemData)
        .then(() => {
          toast.success('Organization added');
          onClose();
          onRefreshData();
        })
        .catch((err) => {
          const status = err.response_status;
          let message = '';
          switch (status) {
            case 400:
              message = "Error, can't update organization";
              break;
            case 406:
              message = "Can't create an organization with the same name within the same committee";
              break;
            default:
              message = "Unexpected error, can't update organization";
              break;
          }
          if (status) toast.error(message);
        });
    }
  };

  const onRefreshData = () => {
    dispatch(setUpdate({ update: true }));
  };

  return (
    <OrganismModalView
      data={itemData}
      countries={countries}
      selectByCountry={selectByCountry}
      selectedCountry={selectedCountry}
      onSelectByCountryChange={handleOnSelectByCountryChange}
      onPriorityChange={handleOnPriorityChange}
      onCountryChange={handleOnCountryChange}
      onInputChange={handleOnInputChange}
      onSave={handleOnSave}
      onClose={onClose}
    />
  );
};

export default OrganismModalComponent;
