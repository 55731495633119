import request from '../api-connector';

export const getPaymentService = () => {
  const serviceDef = {
    url: 'events-schools/payment-detail',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const confirmParticipationService = () => {
  const serviceDef = {
    url: 'events-schools/confirm-assistance',
    method: 'post',
    params: null
  };
  return request(serviceDef);
};

export const savePaymentImageService = ({ file }) => {
  const dataForm = new FormData();
  dataForm.append('file', file);

  const serviceDef = {
    url: 'events-schools/upload-file',
    method: 'post',
    params: dataForm
  };
  return request(serviceDef);
};
