/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import './CommitteeModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import MUITextField from '../../../components/inputs/MUITextField';

const CommitteeModalView = ({ data, onInputChange, onClose, onSave }) => {
  return (
    <ModalComponent
      open={true}
      title={data?.id ? 'Edit committee' : 'Add committee'}
      onClose={onClose}
      onConfirm={onSave}
    >
     <div className="commiteemodal">
          <MUITextField
          label="Code"
          name="code"
          value={data?.code}
          type="text"
          onChange={onInputChange}
        />
        <MUITextField
          label="Title"
          name="name"
          value={data?.name}
          type="text"
          onChange={onInputChange}
        />

      </div>
    </ModalComponent>
  );
};

export default CommitteeModalView;
