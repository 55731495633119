/** Import react/libraries section **/
import React, { useState, useEffect, useCallback } from 'react';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/

/** Import services section **/
import {
  getEventsService,
  deleteEventService,
  activeEventService
} from '../../api/services/events-services';

/** Import component section **/
import EventsView from './EventsView';
import EventModal from './EventModal';
import ModalComponent from '../../components/Modal';

const EventModel = {
  id: null,
  name: null,
  date: null,
  event_price: 0
};

function EventsComponent() {
  const [inProcess, setInProcess] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalMode, setModalMode] = useState('');

  const getEvents = useCallback(() => {
    setInProcess(true);
    getEventsService()
      .then((response) => {
        setEvents(response);
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't load information");
        console.error('Error getting schools: ', err);
      })
      .finally(() => {
        setInProcess(false);
      });
  }, []);

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  const handleOnAdd = () => {
    const newItem = JSON.parse(JSON.stringify(EventModel));
    setSelectedItem(newItem);
    setModalMode('add');
  };

  const handleOnEdit = (row) => {
    setSelectedItem(row);
    setModalMode('edit');
  };

  const handleOnDelete = (row) => {
    setSelectedItem(row);
    setModalMode('delete');
  };

  const handleOnModalClose = () => {
    clearStates();
  };

  const handleOnDeleteConfirm = () => {
    deleteEventService(selectedItem)
      .then((response) => {
        toast.success('Event deleted');
      })
      .catch((err) => {
        let errStatus = err?.response_status;
        let errMessage = "Error, can't delete event";
        if (errStatus === 403) {
          errMessage = "This event can't  be deleted";
        }
        toast.error(errMessage);
        console.error('Error deleting event: ', err);
      })
      .finally(() => {
        clearStates();
        getEvents();
      });
  };

  const handleOnActive = (row) => {
    setSelectedItem(row);
    setModalMode('active');
  };

  const handleOnActiveConfirm = () => {
    let params = {
      id: selectedItem.id,
      active: !selectedItem.active
    };
    activeEventService(params)
      .then((response) => {
        toast.success('Event status changed');
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Error, can't change status");
        console.error('Error changing status: ', err);
      })
      .finally(() => {
        clearStates();
        getEvents();
      });
  };

  const handleOnUpdateList = (data) => {
    clearStates();
    getEvents();
  };

  const clearStates = () => {
    setSelectedItem(null);
    setModalMode('');
  };

  return (
    <>
      <EventsView
        inProcess={inProcess}
        data={events}
        onAdd={handleOnAdd}
        onActive={handleOnActive}
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
      />
      {selectedItem && (modalMode === 'add' || modalMode === 'edit') && (
        <EventModal
          item={selectedItem}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedItem && modalMode === 'delete' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Delete Event"
        >
          <>{'Are you sure to delete event ' + selectedItem.name + ' ?'}</>
        </ModalComponent>
      )}
      {selectedItem && modalMode === 'active' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnActiveConfirm}
          title={selectedItem.active ? 'Inactive event' : 'Active event'}
        >
          {selectedItem.active ? (
            <>Are you sure to inactive this event?</>
          ) : (
            <>
              <div>Are you sure to active this event?.</div>
              <div className="events_alerttext">
                Any other event active, will be inactive automatically.
              </div>
            </>
          )}
        </ModalComponent>
      )}
    </>
  );
}

export default EventsComponent;
