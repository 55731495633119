/** Import react/libraries section **/
import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

import { useSelector } from 'react-redux';

/** Import resources section **/

/** Import component section **/
import ContentSwitch from '../ContentSwitch';
import HeaderComponent from '../Header';

import LoginComponent from '../../views/Login';
import RegistrationComponent from '../../views/Registration';
import EmailValidationComponent from '../../views/EmailValidation';
import ChangePasswordRequestComponent from '../../views/ChangePasswordRequest';
import ChangePasswordComponent from '../../views/ChangePassword';

/** Import helpers section **/
import {
  REGISTRATION_ROUTE,
  LOGIN_ROUTE,
  EMAIL_VALIDATION_ROUTE,
  CHANGE_PASSWORD_REQUEST_ROUTE,
  CHANGE_PASSWORD_ROUTE
} from '../../URL-routes';

/** Import resources section **/
import './MainContainer.scss';
import Toolbar from '@material-ui/core/Toolbar';

/**
 * Help us to detect if user already logged in, in that case we stay, if not redirect to Login page.
 * @param children
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
function PrivateRoutes({ children, ...rest }) {
  let token = useSelector((store) => store.session.token);
  let role = useSelector((store) => store.session.role);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          <div className="maincontainer">
            <Toolbar className="maincontainer_toolbar">
              <HeaderComponent />
            </Toolbar>
            <div className="maincontainer_content">
              {role && <ContentSwitch role={role} />}
            </div>
          </div>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const MainContainer = () => {
  return (
    <Router>
      <div className="maincontainer">
        <Switch>
          <Route exact path={LOGIN_ROUTE} component={LoginComponent} />
          <Route
            exact
            path={REGISTRATION_ROUTE}
            component={RegistrationComponent}
          />
          <Route
            exact
            path={EMAIL_VALIDATION_ROUTE + '/:token'}
            component={EmailValidationComponent}
          />
          <Route
            exact
            path={CHANGE_PASSWORD_REQUEST_ROUTE}
            component={ChangePasswordRequestComponent}
          />

          <Route
            exact
            path={CHANGE_PASSWORD_ROUTE + '/:token'}
            component={ChangePasswordComponent}
          />

          <PrivateRoutes>
            <div className="content-container">
              <ContentSwitch />
            </div>
          </PrivateRoutes>
        </Switch>
      </div>
    </Router>
  );
};

export default MainContainer;
